import React, { useRef, useEffect } from 'react';
import '../style/casestudies.css';

const CaseStudies = () => {
  const timelineRefs = useRef([]);
  const cursorRef = useRef(null);
  const timelineContainerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.2 }
    );

    timelineRefs.current.forEach((ref) => ref && observer.observe(ref));

    const updateCursorPosition = () => {
      const timelineContainer = timelineContainerRef.current;
      const cursor = cursorRef.current;
      if (!timelineContainer || !cursor) return;

      const scrollTop = window.scrollY || window.pageYOffset;
      const timelineTop = timelineContainer.getBoundingClientRect().top + window.scrollY;
      const containerHeight = timelineContainer.offsetHeight;
      const timelineHeight = containerHeight * 0.8; // 80% of container height (matches .timeline-line)
      const timelineStart = timelineTop + containerHeight * 0.1; // 10% offset from top
      const windowHeight = window.innerHeight;

      // Calculate scroll progress based on viewport middle
      const scrollProgress = scrollTop + windowHeight / 2; // Middle of the viewport

      // Calculate progress within timeline bounds (0 to 1)
      const progress = Math.min(
        Math.max((scrollProgress - timelineStart) / timelineHeight, 0),
        1
      );

      // Define the exact bounds for the cursor within the timeline-line
      const cursorHeight = cursor.offsetHeight;
      const minCursorPosition = 0; // Top of the timeline-line (10% from container top)
      const maxCursorPosition = timelineHeight - cursorHeight; // Bottom of the timeline-line

      // Calculate cursor position within the bounds
      const cursorPosition = progress * maxCursorPosition;

      // Apply the position with the initial offset (10% from container top)
      cursor.style.transform = `translate(-50%, ${minCursorPosition + cursorPosition}px)`;
    };

    window.addEventListener('scroll', updateCursorPosition);
    updateCursorPosition(); // Initial position

    return () => {
      window.removeEventListener('scroll', updateCursorPosition);
      observer.disconnect();
    };
  }, []);

  const caseStudies = [
    {
      shade: 'shade-indigo',
      side: 'side-left',
      image: 'https://theconstructor.org/wp-content/uploads/2021/05/ML1-scaled.jpg',
      title: 'Car Wash Mobile Application',
      description: 'India’s massive mobile market offers huge potential, but Kerala’s urban-rural divide shapes how apps succeed. Our Car Wash app was built to win over both crowds with a smart, split approach.',
    },
    {
      shade: 'shade-emerald',
      side: 'side-right',
      image: 'https://searchlightcap.com/wp-content/cache/thumb/db/134233f8fc6cfdb_600x604.jpg',
      title: 'Subscription Nature of Kerala (Rural and Urban Areas)',
      description: 'Kerala’s subscription habits mirror its economic diversity, and our Car Wash app taps into this with a flexible model. We aimed to balance accessibility and revenue across its varied landscape.',
    },
    {
      shade: 'shade-amber',
      side: 'side-left',
      image: 'https://img.freepik.com/premium-vector/set-profile-mobile-screens-with-ui-different-apps_504060-300.jpg?w=2000',
      title: 'Need for SaaS Application in Small Apps',
      description: 'Small apps need big scalability to grow, and our Car Wash app proves it with a SaaS backbone. We set out to lift a local business with enterprise-grade tools.',
    },
    {
      shade: 'shade-indigo',
      side: 'side-right',
      image: 'https://th.bing.com/th/id/OIP.i1QVImXBo7Ff-IhlmNmHmwHaE5?rs=1&pid=ImgDetMain',
      title: 'Water Resources Across Countries',
      description: 'Water use in car washing varies globally, and sustainability is key in resource-scarce areas like India. Our Car Wash app tackles this with eco-friendly options that resonate locally and beyond.',
    },
  ];

  return (
    <section className="case-studies-section">
      <div className="content-container">
        <h2 className="section-title">Case Studies</h2>
        <div className="timeline-container" ref={timelineContainerRef}>
          <div className="timeline-line">
            <div className="timeline-cursor" ref={cursorRef}></div>
          </div>
          {caseStudies.map((study, index) => (
            <div
              key={index}
              className={`timeline-item ${study.shade} ${study.side}`}
              ref={(el) => (timelineRefs.current[index] = el)}
            >
              <img
                src={study.image}
                alt={study.title}
                className={`timeline-image ${study.side}`}
              />
              <div className={`timeline-content ${study.side}`}>
                <h3 className="feature-title">{study.title}</h3>
                <p className="timeline-description">{study.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseStudies;