import React from 'react';
import '../style/contact.css';

const Contact = () => (
  <section className="contact-section">
    <div className="content-container">
      <div className="contact-split">
        <div className="contact-left">
          <h2 className="section-title">Talk to Us </h2>
          <p>We're here to help you with your next project. Reach out today!</p>
        </div>
        <div className="contact-right">
          <form className="contact-form">
            <input type="text" placeholder="Your Name" className="input shade-indigo" />
            <input type="email" placeholder="Your Email" className="input shade-emerald" />
            <textarea placeholder="Your Message" className="textarea shade-amber"></textarea>
            <button type="submit" className="submit-button">Send Message</button>
          </form>
        </div>
      </div>
    </div>
  </section>
);

export default Contact;