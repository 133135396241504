// Footer.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Constants
const COLORS = {
  background: '#000000',
  accent: '#FFFFFF', // White
  textLight: '#FFFFFF',
  textDark: '#1A1A1A',
  cardBg: '#1A1A1A', // Kept in constants but won’t be used
  textMuted: '#808080',
  redGlow: '#FFFFFF',
  yellowTouch: '#FDE68A', // Amber for yellow touch
};

const BREAKPOINTS = {
  small: '400px',
  medium: '800px',
  large: '1400px',
};

// Keyframes
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 5px ${COLORS.yellowTouch}; }
  50% { box-shadow: 0 0 15px ${COLORS.yellowTouch}; }
  100% { box-shadow: 0 0 5px ${COLORS.yellowTouch}; }
`;

// Styled Components
const FooterContainer = styled.footer`
  width: 100%;
  background: ${COLORS.background};
  padding: clamp(2rem, 3vw, 3rem) 0; // Responsive padding
  border-top: 2px solid ${COLORS.yellowTouch};
  animation: ${fadeIn} 2s ease-out;
`;

const FooterContent = styled.div`
  max-width: ${BREAKPOINTS.large};
  margin: 0 auto;
  padding: 0 clamp(0.5rem, 2vw, 2rem); // Responsive padding
  display: flex;
  justify-content: space-between;
  gap: clamp(1rem, 2vw, 2rem); // Responsive gap
  background: ${COLORS.background};

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    gap: 1.5rem;
  }
`;

const FooterSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: clamp(0.5rem, 1vw, 1rem); // Responsive gap

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
    max-width: 400px; // Limit width on tablet for better readability
  }
`;

const FooterTitle = styled.h3`
  font-size: clamp(1.2rem, 2vw, 1.5rem); // Responsive font size
  font-weight: 600;
  color: ${COLORS.accent};
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 3px;
    background: ${COLORS.yellowTouch};
    transition: width 0.3s ease;
  }

  &:hover:after {
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    &:after {
      left: 50%;
      transform: translateX(-50%); // Center the underline on tablet and mobile
    }
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1rem, 1.5vw, 1.3rem);
  }
`;

const FooterText = styled.p`
  font-size: clamp(0.9rem, 1.5vw, 1rem); // Responsive font size
  color: ${COLORS.textLight};
  line-height: 1.5;
  transition: color 0.3s ease;

  &:hover {
    color: ${COLORS.yellowTouch};
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 0.9rem);
  }
`;

const FooterLink = styled.a`
  font-size: clamp(0.9rem, 1.5vw, 1rem); // Responsive font size
  color: ${COLORS.textLight};
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: ${COLORS.yellowTouch};
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 0.9rem);
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: clamp(0.5rem, 1vw, 1rem); // Responsive gap

  @media (max-width: ${BREAKPOINTS.medium}) {
    justify-content: center;
  }
`;

const SocialIcon = styled.a`
  width: clamp(32px, 5vw, 40px); // Responsive size
  height: clamp(32px, 5vw, 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(1rem, 1.5vw, 1.2rem); // Responsive font size
  color: ${COLORS.accent};
  background: ${COLORS.background};
  border-radius: 50%;
  text-decoration: none;
  border: 1px solid ${COLORS.yellowTouch};
  transition: all 0.3s ease;

  &:hover {
    color: ${COLORS.yellowTouch};
    background: rgba(${props => hexToRgb(props.shadeColor || COLORS.yellowTouch)}, 0.3);
    animation: ${glow} 1.5s infinite ease-in-out;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    width: clamp(28px, 4vw, 32px);
    height: clamp(28px, 4vw, 32px);
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }
`;

// Helper function to convert hex to RGB
const hexToRgb = (hex) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r}, ${g}, ${b}`;
};

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <FooterSection>
        <FooterTitle>About Us</FooterTitle>
        <FooterText>Leading IT and media solutions provider.</FooterText>
      </FooterSection>
      <FooterSection>
        <FooterTitle>Services</FooterTitle>
        <FooterLink href="#">Web Development</FooterLink>
        <FooterLink href="#">Digital Marketing</FooterLink>
        <FooterLink href="#">Branding</FooterLink>
      </FooterSection>
      <FooterSection>
        <FooterTitle>Contact</FooterTitle>
        <FooterText>Email: info@crudops.com</FooterText>
        <FooterText>Phone: +91 6238575185</FooterText>
        <FooterText>Address: Kuruppampady, Kochi, India</FooterText>
      </FooterSection>
      <FooterSection>
        <FooterTitle>Follow Us</FooterTitle>
        <SocialIcons>
          <SocialIcon href="https://www.facebook.com/crudopspvtltd" shadeColor="#FDE68A">F</SocialIcon> {/* Facebook */}
          <SocialIcon href="#" shadeColor="#FDE68A">T</SocialIcon> {/* Twitter */}
          <SocialIcon href="https://www.instagram.com/crudops.tech/" shadeColor="#FDE68A">I</SocialIcon> {/* Instagram */}
          <SocialIcon href="https://www.linkedin.com/company/crudops/?originalSubdomain=in" shadeColor="#FDE68A">L</SocialIcon> {/* LinkedIn */}
        </SocialIcons>
      </FooterSection>
    </FooterContent>
  </FooterContainer>
);

export default Footer;