import styled, { keyframes, css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const COLORS = {
  background: '#000000',
  accent: '#FFFFFF',
  textLight: '#FFFFFF',
  textDark: '#1A1A1A',
  cardBg: '#1A1A1A',
  textMuted: '#808080',
  redGlow: '#FFFFFF',
};

export const FONT_SIZES = {
  small: '1rem',
  medium: '1.25rem',
  large: '2.5rem',
};

export const BREAKPOINTS = {
  small: '400px',
  medium: '800px',
  large: '1400px',
};

// Keyframes
export const popUp = keyframes`
  0% { transform: scale(0.8); opacity: 0; translate: 0 20px; }
  100% { transform: scale(1); opacity: 1; translate: 0 0; }
`;

export const floatAnimation1 = keyframes`
  0% { transform: translate(0, 0) rotateX(5deg) rotateY(-5deg); }
  50% { transform: translate(-5px, -10px) rotateX(8deg) rotateY(-8deg); }
  100% { transform: translate(0, 0) rotateX(5deg) rotateY(-5deg); }
`;

export const floatAnimation2 = keyframes`
  0% { transform: translate(0, 0) rotateX(0deg) rotateY(0deg); }
  50% { transform: translate(0, -15px) rotateX(5deg) rotateY(5deg); }
  100% { transform: translate(0, 0) rotateX(0deg) rotateY(0deg); }
`;

export const floatAnimation3 = keyframes`
  0% { transform: translate(0, 0) rotateX(-5deg) rotateY(5deg); }
  50% { transform: translate(5px, -10px) rotateX(-8deg) rotateY(8deg); }
  100% { transform: translate(0, 0) rotateX(-5deg) rotateY(5deg); }
`;

export const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const slideUp = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

export const parallax = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 0 -50px; }
`;

export const zoomParallax = keyframes`
  0% { transform: scale(1); }
  100% { transform: scale(1.2); }
`;

export const horizontalParallax = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: -50px 0; }
`;

export const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(15px) scale(0.95); }
  to { opacity: 1; transform: translateY(0) scale(1); }
`;

export const fadeInDown = keyframes`
  from { opacity: 0; transform: translateY(-15px); }
  to { opacity: 1; transform: translateY(0); }
`;

export const glow = keyframes`
  0% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.3); }
  50% { box-shadow: 0 0 15px rgba(255, 255, 255, 0.6); }
  100% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.3); }
`;

export const slideIn = keyframes`
  from { transform: translateY(50px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

export const cardFadeIn = keyframes`
  from { transform: scale(0.8) translateY(20px); opacity: 0; }
  to { transform: scale(1) translateY(0); opacity: 1; }
`;

export const cardFadeOut = keyframes`
  from { transform: scale(1) translateY(0); opacity: 1; }
  to { transform: scale(0.8) translateY(20px); opacity: 0; }
`;

export const backgroundShift = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

export const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${COLORS.background};
  color: ${COLORS.textLight};
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionWrapper = styled.section`
  width: 100%;
  min-height: 80vh;
  padding: clamp(3rem, 5vw, 5rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.large};
  padding: 0 clamp(1rem, 2vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 2; /* Ensure content is above the video */
`;

export const Logo = styled.img`
  width: clamp(100px, 15vw, 150px);
  margin-bottom: 1.5rem;
  animation: ${pulse} 3s infinite ease-in-out;
  filter: grayscale(100%) brightness(150%);
`;

export const SectionTitle = styled.h2`
  font-size: clamp(2rem, 3.5vw, 3rem);
  font-weight: 700;
  text-align: center;
  color: ${COLORS.accent};
  margin-bottom: 3rem;
  text-shadow: 0 2px 5px rgba(255, 255, 255, 0.2);
`;

export const FeatureSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.2s ease-out;
  position: relative;
  overflow: hidden;
  padding: clamp(4rem, 6vw, 6rem) 0;

  & > * {
    position: relative;
    z-index: 1;
  }
`;

export const AboutSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.8s ease-out;
  position: relative;
  overflow: hidden;

  & > * {
    position: relative;
    z-index: 1;
  }

  .about-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 2rem;
    animation: ${floatAnimation1} 3s infinite ease-in-out;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    filter: grayscale(100%);

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 10px 30px rgba(255, 255, 255, 0.3);
      filter: grayscale(0%);
    }
  }
`;

export const ShowcaseSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.4s ease-out;
  position: relative;
  overflow: hidden;

  & > * {
    position: relative;
    z-index: 1;
  }
`;

export const ContactSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.6s ease-out;
`;

export const TestimonialsSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 2s ease-out;
`;

export const BlogSection = styled(SectionWrapper)`
  background: url('https://www.example.com/video.mp4') no-repeat center center; /* Replace with your video URL */
  background-size: cover;
  position: relative;
  overflow: hidden;
  animation: ${slideUp} 2.2s ease-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for readability */
    z-index: 1;
  }

  & > ${ContentContainer} {
    position: relative;
    z-index: 2; /* Ensure content is above the video */
  }

  /* Video styling with fallback for browsers that support <video> */
  & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
`;

export const TeamSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 2.4s ease-out;
`;

export const FAQSection = styled(SectionWrapper)`
  background: ${COLORS.cardBg};
  animation: ${slideUp} 2.6s ease-out;
`;

export const PricingSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 2.8s ease-out;
`;

export const CaseStudiesSection = styled(SectionWrapper)`
  background: linear-gradient(135deg, ${COLORS.cardBg}, ${COLORS.background});
  animation: ${slideUp} 3s ease-out;
`;

export const EventsSection = styled(SectionWrapper)`
  background: ${COLORS.cardBg};
  animation: ${slideUp} 3.2s ease-out;
`;

export const CareerSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 3.4s ease-out;
`;

export const PartnersSection = styled(SectionWrapper)`
  background: ${COLORS.cardBg};
  animation: ${slideUp} 3.6s ease-out;
`;

export const ClientsSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 3.8s ease-out;

  & > ${ContentContainer} {
    background: ${COLORS.accent};
    border-radius: 20px;
    padding: clamp(2rem, 4vw, 4rem);
    align-items: center;
  }
`;

export const IndustriesSection = styled(SectionWrapper)`
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.1) 0%, ${COLORS.background} 70%);
  animation: ${slideUp} 1.2s ease-out;
  position: relative;
  overflow: hidden;
  padding: clamp(4rem, 6vw, 6rem) 0;
`;

export const HeroSection = styled(SectionWrapper)`
  // background: url('https://i.postimg.cc/DzBRZJYq/programming-background-with-person-working-with-codes-computer.jpg') no-repeat center center;
  background-size: cover;
  animation: ${fadeIn} 1s ease-out;
  position: relative;
  overflow: hidden;
  padding: clamp(4rem, 8vw, 10rem) 0;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  filter: grayscale(100%);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  & > ${ContentContainer} {
    position: relative;
    z-index: 2;
    margin-bottom: 4rem;
  }
`;

export const FeatureCard = styled.div`
  background: linear-gradient(135deg, ${COLORS.cardBg}, rgba(0, 0, 0, 0.9));
  padding: 2.5rem;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 500px;
  margin: 1rem 0;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.1) 0%, transparent 70%);
    animation: ${backgroundShift} 5s infinite ease-in-out;
    z-index: 0;
  }

  &:hover,
  &:active {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(255, 255, 255, 0.3);
    border: 2px solid ${COLORS.accent};
  }

  &.fade-in {
    animation: ${cardFadeIn} 0.8s ease-out forwards;
  }

  &.fade-out {
    animation: ${cardFadeOut} 0.8s ease-out forwards;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
`;

export const ShowcaseCard = styled(FeatureCard)`
  background: linear-gradient(135deg, ${COLORS.cardBg}, rgba(0, 0, 0, 0.9));
  padding: 1.5rem;
  text-align: center;
  max-width: 400px;
  min-height: 300px;

  &:hover,
  &:active {
    border-radius: 50%;
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(255, 255, 255, 0.2);
  }
`;

export const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 100%;
  padding: 0 1rem;
  max-width: ${BREAKPOINTS.large};
  margin: 0 auto;

  & > ${FeatureCard}, & > ${ShowcaseCard} {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  & > ${ShowcaseCard}:nth-child(n + 4) {
    display: none;
  }

  ${FeatureSection} & {
    & > ${FeatureCard} {
      &:nth-child(1) { animation: ${floatAnimation1} 4s infinite ease-in-out; }
      &:nth-child(2) { animation: ${floatAnimation2} 4.2s infinite ease-in-out; }
      &:nth-child(3) { animation: ${floatAnimation3} 4.4s infinite ease-in-out; }
    }

    @media (max-width: ${BREAKPOINTS.medium}) {
      grid-template-columns: repeat(2, 1fr);
      & > ${FeatureCard}:nth-child(3) { grid-column: span 2; }
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      grid-template-columns: 1fr;
      & > ${FeatureCard} { animation: none; }
    }
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    grid-template-columns: repeat(2, 1fr);
    & > ${ShowcaseCard}:nth-child(3) { grid-column: span 2; }
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    grid-template-columns: 1fr;
  }
`;

export const ClientGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 100%;
  padding: 0 1rem;
  max-width: ${BREAKPOINTS.large};
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    grid-template-columns: 1fr;
  }
`;

export const PartnerLogo = styled.img`
  width: clamp(120px, 15vw, 150px); /* Adjusted to match image size */
  height: clamp(120px, 15vw, 150px); /* Ensure square shape for round effect */
  object-fit: contain; /* Ensures the image fits within the round frame */
  border-radius: 50%; /* Makes the image round */
  opacity: 0.7;
  transition: opacity 0.3s ease, transform 0.3s ease;
  filter: grayscale(100%);

  &:hover {
    opacity: 1;
    filter: grayscale(0%);
    transform: scale(1.1); /* Slight scale on hover */
  }
`;

export const FeatureIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: ${COLORS.accent};
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
`;

export const FeatureTitle = styled.h3`
  font-size: 2rem;
  color: ${COLORS.textLight};
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const FeatureSubtitle = styled.p`
  font-size: 1.1rem;
  color: ${COLORS.textMuted};
  line-height: 1.6;
  max-width: 400px;
`;

export const AboutCardImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 1rem;
  filter: grayscale(100%);
  transition: filter 0.5s ease, transform 0.5s ease;

  ${FeatureCard}:hover & {
    filter: grayscale(0%);
    transform: scale(1.05);
  }
`;

export const ShowcaseImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
  filter: grayscale(100%);
  transition: transform 0.5s ease, filter 0.5s ease;

  ${ShowcaseCard}:hover & {
    filter: grayscale(0%);
    transform: scale(1.1);
  }
`;

export const TestimonialCard = styled(FeatureCard)`
  text-align: center;
  padding: 1.5rem;
  border-left: 4px solid ${COLORS.accent};
`;

export const BlogCard = styled(FeatureCard)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BlogImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
  margin-bottom: 1rem;
  filter: grayscale(100%);
`;

export const TeamCard = styled(FeatureCard)`
  text-align: center;
  padding: 1.5rem;
`;

export const FAQItem = styled.div`
  background: ${COLORS.cardBg};
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: ${COLORS.accent};
    color: ${COLORS.background};
  }
`;

export const FAQQuestion = styled.h4`
  font-size: ${FONT_SIZES.medium};
  margin-bottom: 0.5rem;
  color: ${COLORS.textLight};
`;

export const FAQAnswer = styled.p`
  font-size: ${FONT_SIZES.small};
  color: ${COLORS.textMuted};
`;

export const PricingCard = styled(FeatureCard)`
  text-align: center;
  padding: 2rem;
  border-top: 2px solid ${COLORS.accent};
`;

export const CaseStudyCard = styled(FeatureCard)`
  position: relative;
  overflow: hidden;
`;

export const ActionButton = styled.button`
  padding: 1.2rem 3rem;
  background: ${COLORS.accent};
  border: none;
  border-radius: 50px;
  font-size: ${FONT_SIZES.medium};
  font-weight: 700;
  color: ${COLORS.background};
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 6px 25px rgba(255, 255, 255, 0.3);
  margin-top: 2rem;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 35px rgba(255, 255, 255, 0.5);
  }
`;

export const CaseStudyImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 1rem;
  transition: all 0.5s ease;
  filter: grayscale(100%);

  ${CaseStudyCard}:hover & {
    transform: scale(1.05);
    filter: grayscale(0%);
  }
`;

export const EventCard = styled(FeatureCard)`
  text-align: center;
  padding: 1.5rem;
`;

export const CareerCard = styled(FeatureCard)`
  text-align: center;
  padding: 1.5rem;
`;

export const HeroSubtitle = styled.p`
  font-size: clamp(1.2rem, 2vw, 1.8rem);
  color: ${COLORS.textLight};
  max-width: 700px;
  margin: 0 0 1rem 0;
  opacity: 0.85;
  line-height: 1.6;
  text-align: left;
  margin-left: -10rem;
`;

export const HeroTitle = styled.h1`
  font-size: clamp(3rem, 6vw, 5rem);
  font-weight: 800;
  padding: 0;
  color: ${COLORS.textLight};
  margin-top: 18rem;
  margin-bottom: 1rem;
  text-shadow: 0 4px 15px rgba(255, 255, 255, 0.3);
  line-height: 1.2;
  text-align: left;
  margin-left: -10rem;
`;

export const HeroContent = styled.div`
  text-align: left;
  z-index: 1;
  position: relative;
`;

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${COLORS.background};
  box-shadow: 0 2px 15px rgba(255, 255, 255, 0.1);
  z-index: 1000;
  padding: clamp(1rem, 2.5vw, 1.5rem) clamp(1rem, 3vw, 2rem);
`;

export const NavContent = styled.div`
  max-width: ${BREAKPOINTS.large};
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TextLogo = styled.div`
  font-size: clamp(1.4rem, 3vw, 2rem);
  font-weight: 800;
  color: ${COLORS.accent};
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
`;

export const NavLinks = styled.div`
  display: flex;
  gap: clamp(1.2rem, 2.5vw, 2.5rem);
  animation: ${fadeInDown} 0.5s ease-out;

  @media (max-width: ${BREAKPOINTS.medium}) {
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: ${COLORS.background};
    padding: 1.5rem;
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.1);
  }
`;

export const StyledNavLink = styled(NavLink)`
  color: ${COLORS.textLight};
  text-decoration: none;
  font-size: clamp(1rem, 1.8vw, 1.2rem);
  font-weight: 600;
  padding: 0.5rem 0;
  transition: all 0.3s ease;

  &:hover {
    color: ${COLORS.accent};
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
  }

  &.active {
    color: ${COLORS.accent};
    border-bottom: 2px solid ${COLORS.accent};
  }
`;

export const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${COLORS.accent};
  font-size: clamp(1.4rem, 3.5vw, 2rem);
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    display: block;
  }
`;

export const FooterContainer = styled.footer`
  background: ${COLORS.accent};
  color: ${COLORS.background};
  padding: clamp(3rem, 4vw, 4rem) clamp(1rem, 2vw, 2rem);
  margin-top: auto;
  width: 100%;
`;

export const FooterContent = styled.div`
  max-width: ${BREAKPOINTS.large};
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(180px, 25vw, 220px), 1fr));
  gap: clamp(1.5rem, 2.5vw, 2.5rem);
`;

export const FooterSection = styled.div`
  animation: ${fadeInUp} 0.8s ease-out both;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FooterTitle = styled.h3`
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  font-weight: 600;
  color: ${COLORS.background};
  margin-bottom: clamp(1rem, 1.5vw, 1.2rem);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

export const FooterText = styled.p`
  font-size: clamp(0.85rem, 1.3vw, 0.95rem);
  color: ${COLORS.background};
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
  transition: color 0.3s ease;

  &:hover {
    color: ${COLORS.textDark};
  }
`;

export const FooterLink = styled.a`
  color: ${COLORS.background};
  text-decoration: none;
  font-size: clamp(0.85rem, 1.3vw, 0.95rem);
  display: block;
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
  transition: color 0.3s ease;

  &:hover {
    color: ${COLORS.textDark};
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: clamp(0.8rem, 1.2vw, 1.2rem);
  margin-top: clamp(1rem, 1.5vw, 1.2rem);
`;

export const SocialIcon = styled.a`
  width: clamp(32px, 4vw, 40px);
  height: clamp(32px, 4vw, 40px);
  background: ${COLORS.background};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.accent};
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1) rotate(5deg);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
`;