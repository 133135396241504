import React from "react";
import styled, { keyframes } from "styled-components";

// Constants
const COLORS = {
  background: "#000000",
  primary: "#E0E0E0",
  secondary: "#D4A017",
  accent: "#009ACD",
  textLight: "#FFFFFF",
  textMuted: "#A0A0A0",
};

const FONT_SIZES = {
  small: "1rem",
  medium: "1.25rem",
  large: "2.5rem",
};

const BREAKPOINTS = {
  small: "400px",
  medium: "800px",
  large: "1400px",
};

// Animations
const slideInLeft = keyframes`
  from { transform: translateX(-30px); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const glowPulse = keyframes`
  0% { box-shadow: 0 0 4px rgba(0, 154, 205, 0.4); }
  50% { box-shadow: 0 0 15px rgba(0, 154, 205, 0.6); }
  100% { box-shadow: 0 0 4px rgba(0, 154, 205, 0.4); }
`;

// Styled Components
const SectionWrapper = styled.section`
  width: 100%;
  min-height: 80vh;
  padding: clamp(2rem, 4vw, 5rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: auto;
    padding: clamp(1.5rem, 3vw, 3rem) 0;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(1rem, 2vw, 2rem) 0;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${COLORS.background};
  color: ${COLORS.primary};
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderSection = styled(SectionWrapper)`
  position: relative;
  background: ${COLORS.background};
  animation: ${fadeIn} 1s ease-out;
  min-height: 100vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: 80vh;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: 60vh;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const VideoBackground = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.4;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.large};
  padding: 0 clamp(0.5rem, 1.5vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

const HeaderContent = styled.div`
  text-align: center;
  z-index: 2;
`;

const HeaderTitle = styled.h1`
  font-size: clamp(2rem, 4vw, 4.5rem);
  font-weight: 900;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
  background: linear-gradient(90deg, ${COLORS.accent}, ${COLORS.secondary});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1.5rem, 3vw, 2.5rem);
  }
`;

const HeaderSubtitle = styled.p`
  font-size: clamp(0.9rem, 1.5vw, 1.5rem);
  color: ${COLORS.textLight};
  max-width: clamp(400px, 60vw, 600px);
  margin: 0 auto clamp(1rem, 2vw, 2rem);
  font-weight: 500;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }
`;

const ActionButton = styled.button`
  padding: clamp(0.8rem, 1.5vw, 1rem) clamp(1.5rem, 2.5vw, 2.5rem);
  background: ${COLORS.accent};
  border: none;
  border-radius: 8px;
  font-size: clamp(1rem, 1.5vw, ${FONT_SIZES.medium});
  font-weight: 700;
  color: ${COLORS.background};
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgba(0, 154, 205, 0.3);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 154, 205, 0.5);
    animation: ${glowPulse} 1.5s infinite;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(0.6rem, 1vw, 0.8rem) clamp(1rem, 2vw, 1.5rem);
    font-size: clamp(0.9rem, 1.2vw, 1rem);
  }
`;

const SectionTitle = styled.h2`
  font-size: clamp(1.8rem, 3vw, 3rem);
  font-weight: 700;
  text-align: center;
  margin-bottom: clamp(1.5rem, 2vw, 3rem);
  background: linear-gradient(90deg, ${COLORS.accent}, ${COLORS.secondary});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1.5rem, 2.5vw, 2rem);
  }
`;

const ServicesSection = styled.section`
  position: relative;
  padding: 50px 0;
  background: ${COLORS.background};
`;

const ServiceCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: clamp(800px, 90vw, 1200px);
  background: rgba(20, 20, 20, 0.95);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 154, 205, 0.1);
  border: 1px solid rgba(0, 154, 205, 0.2);
  margin-bottom: clamp(2rem, 3vw, 4rem);
  transition: all 0.4s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 30px rgba(0, 154, 205, 0.3);
    animation: ${glowPulse} 1.5s infinite;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column;
    max-width: 90vw;
  }
`;

const ServiceImageWrapper = styled.div`
  flex: 1;
  position: relative;
  min-height: clamp(250px, 35vh, 350px);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
    min-height: clamp(200px, 30vh, 300px);
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;

  ${ServiceCard}:hover & {
    transform: scale(1.05);
  }
`;

const ServiceContent = styled.div`
  flex: 1;
  padding: clamp(1.5rem, 2vw, 2rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${COLORS.textLight};

  @media (max-width: ${BREAKPOINTS.medium}) {
    text-align: center;
    padding: clamp(1rem, 1.5vw, 1.5rem);
  }
`;

const ServiceTitle = styled.h3`
  font-size: clamp(1.5rem, 2vw, 1.8rem);
  font-weight: 700;
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
  background: linear-gradient(90deg, ${COLORS.accent}, ${COLORS.secondary});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ServiceSubtitle = styled.p`
  font-size: clamp(0.9rem, 1.2vw, 1.1rem);
  font-weight: 500;
  color: ${COLORS.accent};
  margin-bottom: clamp(0.8rem, 1vw, 1.2rem);
  text-transform: uppercase;
`;

const ServiceDescription = styled.p`
  font-size: clamp(0.9rem, 1.5vw, ${FONT_SIZES.medium});
  color: ${COLORS.textMuted};
  line-height: 1.6;
`;

const ServiceButton = styled.button`
  margin-top: clamp(1rem, 1.5vw, 1.5rem);
  padding: clamp(0.5rem, 1vw, 0.8rem) clamp(1rem, 2vw, 1.5rem);
  background: transparent;
  border: 2px solid ${COLORS.accent};
  border-radius: 8px;
  font-size: clamp(0.9rem, 1.2vw, 1rem);
  font-weight: 600;
  color: ${COLORS.textLight};
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;

  &:hover {
    background: ${COLORS.accent};
    color: ${COLORS.background};
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    align-self: center;
  }
`;

const HighlightsSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideInLeft} 1.4s ease-out;
`;

const HighlightGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(300px, 30vw, 400px), 1fr));
  gap: clamp(1.5rem, 2vw, 3rem);
  width: 100%;
  padding: 0 clamp(0.5rem, 1vw, 1rem);

  @media (max-width: ${BREAKPOINTS.small}) {
    grid-template-columns: 1fr;
    gap: clamp(1rem, 1.5vw, 2rem);
  }
`;

const HighlightCard = styled.div`
  position: relative;
  background: rgba(20, 20, 20, 0.95);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 154, 205, 0.1);
  transition: all 0.4s ease;
  border: 1px solid rgba(0, 154, 205, 0.2);
  height: clamp(400px, 50vh, 500px);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 30px rgba(0, 154, 205, 0.3);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    height: clamp(350px, 45vh, 450px);
  }
`;

const HighlightImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
  transition: opacity 0.4s ease;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  ${HighlightCard}:hover & {
    opacity: 0.8;
  }
`;

const HighlightOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
  z-index: 2;
`;

const HighlightContent = styled.div`
  position: relative;
  z-index: 3;
  padding: clamp(1.5rem, 2vw, 2.5rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${COLORS.textLight};
`;

const HighlightTitle = styled.h3`
  font-size: clamp(1.5rem, 2vw, 1.8rem);
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
  background: linear-gradient(90deg, ${COLORS.accent}, ${COLORS.secondary});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  }
`;

const HighlightDescription = styled.p`
  font-size: clamp(0.9rem, 1.5vw, ${FONT_SIZES.medium});
  color: ${COLORS.textMuted};
  line-height: 1.6;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease;

  ${HighlightCard}:hover & {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }
`;

const HighlightButton = styled.button`
  padding: clamp(0.5rem, 1vw, 0.8rem) clamp(1rem, 2vw, 1.5rem);
  background: transparent;
  border: 2px solid ${COLORS.accent};
  border-radius: 8px;
  font-size: clamp(0.9rem, 1.2vw, 1rem);
  font-weight: 600;
  color: ${COLORS.textLight};
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s ease;

  &:hover {
    background: ${COLORS.accent};
    color: ${COLORS.background};
    transform: translateY(0);
  }

  ${HighlightCard}:hover & {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(0.4rem, 0.8vw, 0.6rem) clamp(0.8rem, 1.5vw, 1.2rem);
    font-size: clamp(0.8rem, 1vw, 0.9rem);
  }
`;

const CTASection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideInLeft} 1.6s ease-out;
`;

// Data
const services = [
  {
    id: 1,
    title: "Custom Software Development",
    // subtitle: "Software Solutions",
    description:
      "At Crudops Private Limited, we understand that every business has distinct needs, which is why our custom software development service is all about personalization. Our expert developers work closely with you to analyze your requirements, ensuring the software aligns perfectly with your workflows. Whether it’s a tool to manage inventory, enhance customer relationships, or automate repetitive tasks, we’ve got you covered. We use the latest technologies to create scalable, secure, and efficient solutions that evolve with your business. Our process includes thorough testing and ongoing support to guarantee flawless performance. ",
    images: [
      { src: "https://cdn.intuji.com/2023/08/Custom-software-development.jpg", alt: "Software Development Mockup 1" },
      { src: "/services/services-2.jpg", alt: "Software Development Mockup 2" },
    ],
  },
  {
    id: 2,
    title: "Mobile App Development",
    // subtitle: "Design Services",
    description:
      "In today’s mobile-first world, having a standout app is essential, and Crudops Private Limited is here to make it happen. Our mobile app development team combines creativity and technical prowess to build apps that are both visually appealing and highly functional. We cater to both iOS and Android platforms, ensuring your app reaches the widest possible audience. From e-commerce apps to productivity tools, we focus on delivering seamless user experiences that keep people coming back. Our development process involves in-depth planning, user-centric design, and rigorous testing to ensure top performance. We also offer post-launch support to keep your app updated and competitive. ",
    images: [
      { src: "https://th.bing.com/th/id/OIP.eSsAdCUevbptplcKvFQqMQHaE8?rs=1&pid=ImgDetMain", alt: "UI/UX Design Mockup 1" },
      { src: "/services/services-3.jpg", alt: "UI/UX Design Mockup 2" },
    ],
  },
  {
    id: 3,
    title: "Web Development",
    subtitle: "Cloud Infrastructure",
    description:
      "Your website is often the first interaction people have with your brand, and at Crudops Private Limited, we make sure it’s unforgettable. Our web development services focus on creating responsive, visually striking sites that work flawlessly across all devices—desktops, tablets, and phones alike. We blend modern design trends with robust coding practices to ensure your site is both beautiful and functional. Whether you need a simple landing page or a complex web application, we tailor every project to your specific goals. Security is a top priority, so we implement the latest safeguards to protect your data and users. ",
    images: [
      { src: "https://th.bing.com/th/id/OIP.uxkX-Vegd_8HQNOFYbdkWgHaE7?rs=1&pid=ImgDetMain", alt: "Cloud Solutions Mockup 1" },
      { src: "/services/services-1.jpg", alt: "Cloud Solutions Mockup 2" },
    ],
  },
  {
    id: 4,
    title: "ERP Development",
    subtitle: "Mobile Applications",
    description:
      "Managing a business with scattered systems can be overwhelming, but Crudops Private Limited is here to streamline it all with our ERP development services. We design enterprise resource planning systems that bring your key operations—like finance, human resources, and inventory—under one unified platform. Our custom ERPs are built to match your business’s unique structure, eliminating inefficiencies and improving collaboration across teams. With real-time data access, you can make informed decisions faster and stay ahead of the curve. We prioritize user-friendly interfaces so your staff can adapt quickly without steep learning curves. ",
    images: [
      { src: "https://th.bing.com/th/id/OIP.ncx0pIbdufL4u1GRNdJH0wHaEu?rs=1&pid=ImgDetMain", alt: "Mobile App Mockup 1" },
      { src: "/services/services-2.jpg", alt: "Mobile App Mockup 2" },
    ],
  },
  {
    id: 5,
    title: "E-Commerce Development",
    subtitle: "Security Solutions",
    description:
      "The world of online shopping is booming, and Crudops Private Limited is your partner in building a thriving e-commerce presence. Our e-commerce development services create platforms that are not only secure and reliable but also designed to delight your customers. We integrate features like easy navigation, fast checkout, and personalized recommendations to enhance the shopping experience. Whether you’re a small business launching your first store or an established brand scaling up, our solutions are built to handle your needs. We ensure your site is optimized for mobile users and search engines, driving more traffic and conversions. Security is paramount, so we implement top-tier encryption to protect transactions and build trust. ",
    images: [
      { src: "https://th.bing.com/th/id/OIP.T-Jdf_DpnFglVtRv6RFv4QHaEK?rs=1&pid=ImgDetMain", alt: "Cybersecurity Mockup 1" },
      { src: "/services/services-3.jpg", alt: "Cybersecurity Mockup 2" },
    ],
  },
  {
    id: 6,
    title: "AI Solutions",
    subtitle: "Analytics Services",
    description:
      "Artificial intelligence is revolutionizing the way businesses operate, and at Crudops Private Limited, we’re at the forefront of this transformation. Our AI solutions are designed to tackle complex challenges, such as optimizing supply chains or predicting market trends with precision. We leverage advanced algorithms and machine learning to build systems that learn, adapt, and improve over time. Whether you need chatbots to enhance customer service or data-driven insights to guide your strategy, our team delivers solutions that make an impact. We prioritize ethical AI practices, ensuring transparency and reliability in every project. ",
    images: [
      { src: "https://th.bing.com/th/id/OIP.akPNJrofoV8Dsl1IDIsJLwHaEd?rs=1&pid=ImgDetMain", alt: "Data Analytics Mockup 1" },
      { src: "/services/services-1.jpg", alt: "Data Analytics Mockup 2" },
    ],
  },
];

// Components
const Header = () => {
  return (
    <HeaderSection id="services">
      <VideoContainer>
        <VideoBackground autoPlay loop muted playsInline>
          <source
            src="https://cdn.pixabay.com/video/2016/08/22/4731-179738656_large.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </VideoBackground>
      </VideoContainer>
      <ContentContainer>
        <HeaderContent>
          <HeaderTitle>Our Services</HeaderTitle>
          <HeaderSubtitle>
            Discover how CrudOps can elevate your business.
          </HeaderSubtitle>
          <ActionButton>Explore Services</ActionButton>
        </HeaderContent>
      </ContentContainer>
    </HeaderSection>
  );
};

const ServicesList = () => {
  return (
    <ServicesSection>
      <ContentContainer>
        <SectionTitle>What We Offer</SectionTitle>
        {services.map((item) => (
          <ServiceCard key={item.id}>
            <ServiceImageWrapper>
              <ServiceImage src={item.images[0].src} alt={item.images[0].alt} />
            </ServiceImageWrapper>
            <ServiceContent>
              <ServiceTitle>{item.title}</ServiceTitle>
              <ServiceSubtitle>{item.subtitle}</ServiceSubtitle>
              <ServiceDescription>{item.description}</ServiceDescription>
              <ServiceButton>Learn More</ServiceButton>
            </ServiceContent>
          </ServiceCard>
        ))}
      </ContentContainer>
    </ServicesSection>
  );
};

const Highlights = () => {
  const highlightItems = [
    {
      title: "Expert Team",
      description:
        "Our skilled professionals bring years of industry experience, ensuring top-notch solutions for your business.",
      image:
        "https://blog.bit.ai/wp-content/uploads/2021/04/Team-work-amico-1024x1024.png",
    },
    {
      title: "Proven Results",
      description:
        "We’ve delivered over 100 successful projects with a focus on client satisfaction, earning trust across industries.",
      image:
        "https://static.vecteezy.com/system/resources/previews/006/937/808/non_2x/flat-isometric-illustration-landing-page-concept-check-the-test-results-vector.jpg",
    },
    {
      title: "24/7 Support",
      description:
        "We offer round-the-clock assistance to ensure your business runs smoothly at all times.",
      image:
        "https://static.vecteezy.com/system/resources/previews/003/805/401/non_2x/service-24-7-concept-call-center-support-illustration-flat-vector.jpg",
    },
  ];

  return (
    <HighlightsSection>
      <ContentContainer>
        <SectionTitle>Why Choose Us</SectionTitle>
        <HighlightGrid>
          {highlightItems.map((item, index) => (
            <HighlightCard key={index}>
              <HighlightImage src={item.image} alt={item.title} />
              <HighlightOverlay />
              <HighlightContent>
                <div>
                  <HighlightTitle>{item.title}</HighlightTitle>
                  <HighlightDescription>{item.description}</HighlightDescription>
                </div>
                <HighlightButton>Learn More</HighlightButton>
              </HighlightContent>
            </HighlightCard>
          ))}
        </HighlightGrid>
      </ContentContainer>
    </HighlightsSection>
  );
};

const CallToAction = () => {
  return (
    <CTASection>
      <ContentContainer>
        <SectionTitle>Get Started Today</SectionTitle>
        <HeaderSubtitle>
          Let’s collaborate to bring your ideas to life. Contact us for a free
          consultation!
        </HeaderSubtitle>
        <ActionButton>Contact Us</ActionButton>
      </ContentContainer>
    </CTASection>
  );
};

// Main Component
const Services = () => {
  return (
    <MainContainer>
      <Header />
      <ServicesList />
      <Highlights />
      <CallToAction />
    </MainContainer>
  );
};

export default Services;