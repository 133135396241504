import React from "react";
import '../style/contactus.css';

const contact = [
  {
    id: 1,
    title: 'Software Development',
    subtitle: 'Software Solutions',
    description: 'We develop custom software solutions tailored to your business needs, ensuring seamless integration, scalability, and efficiency to enhance productivity and drive long-term growth.',
    icon: 'fa fa-code',
    img: "/contact/contactus-1.jpg"
  },
  {
    id: 2,
    title: 'UI/UX Design',
    subtitle: 'Design contact',
    description: 'Our expert team designs stunning, user-friendly interfaces with intuitive navigation, accessibility, and modern aesthetics to enhance engagement, satisfaction, and brand loyalty.',
    icon: 'fa fa-paint-brush',
    img: "/contact/contactus-2.jpg"
  },
  {
    id: 3,
    title: 'Cloud Solutions',
    subtitle: 'Cloud Infrastructure',
    description: 'We provide secure, scalable cloud infrastructure that ensures high availability, cost efficiency, and flexibility, empowering businesses to optimize operations and drive innovation.',
    icon: 'fa fa-cloud',
    img: "/contact/contactus-3.jpg"
  },
  {
    id: 4,
    title: 'Mobile App Development',
    subtitle: 'Mobile Applications',
    description: 'We build cross-platform mobile applications with cutting-edge UI/UX, robust security, and high performance, ensuring an engaging and seamless experience across all devices.',
    icon: 'fa fa-mobile',
    img: "/contact/contactus-1.jpg"
  },
  {
    id: 5,
    title: 'Cybersecurity contact',
    subtitle: 'Security Solutions',
    description: 'Our cybersecurity solutions include risk assessments, penetration testing, and real-time monitoring to protect your digital assets, ensuring compliance and safeguarding against cyber threats.',
    icon: 'fa fa-shield',
    img: "/contact/contactus-2.jpg"
  },
  {
    id: 6,
    title: 'Data Analytics',
    subtitle: 'Analytics contact',
    description: 'Leverage predictive analytics, real-time reporting, and interactive dashboards to uncover trends, optimize decision-making, and drive business success through data-driven insights.',
    icon: 'fa fa-line-chart',
    img: "/contact/contactus-3.jpg"
  },
];

const Header = () => (
  <section id="contact" className="contactus-section">
    <div className="header-section">
      <video className="video-background" autoPlay loop muted playsInline>
        <source src="https://cdn.pixabay.com/video/2016/08/22/4731-179738656_large.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content-container">
        <h1 className="header-title">Our contact</h1>
        <p className="header-subtitle">Let's Get Connected</p>
      </div>
    </div>
    <div className="contact-section">
      <div className="container">
        <div className="contact-split">
          <div className="contact-left">
            <h2 className="section-title">Get in Touch</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3927.842062458984!2d76.51711637479515!3d10.112010989999211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b07e3c375f27641%3A0x4443a67f95cf8cbe!2sCRUDOPS%20Pvt.Ltd!5e0!3m2!1sen!2sin!4v1743628546280!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="contact-right">
            <form className="contact-form">
              <input type="text" placeholder="Your Name" className="input shade-indigo" />
              <input type="email" placeholder="Your Email" className="input shade-emerald" />
              <input type="email" placeholder="Your Phone Number" className="input shade-emerald" />
              <select required  className="input shade-emerald">
                    <option value="" disabled selected>
                      Select Service
                    </option>
                    <option value="software-development">Software Development</option>
                    <option value="ui-ux-design">UI/UX Design</option>
                    <option value="cloud-solutions">Cloud Solutions</option>
                  </select>
              <textarea placeholder="Your Message" className="textarea shade-amber"></textarea>
              <button type="submit" className="submit-button">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Header;
