import React from 'react';
import styled, { keyframes } from 'styled-components';

// Constants
const COLORS = {
  background: '#000000',
  primary: '#E0E0E0',
  secondary: '#D4A017',
  accent: '#009ACD',
  textLight: '#FFFFFF',
  textMuted: '#A0A0A0',
};

const FONT_SIZES = {
  small: '1rem',
  medium: '1.3rem',
  large: '2.8rem',
};

const BREAKPOINTS = {
  small: '400px',
  medium: '800px',
  large: '1400px',
};

// Animations
const slideUp = keyframes`
  0% { transform: translateY(40px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 154, 205, 0.5); }
  70% { box-shadow: 0 0 0 12px rgba(0, 154, 205, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 154, 205, 0); }
`;

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${COLORS.background};
  color: ${COLORS.primary};
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionWrapper = styled.section`
  width: 100%;
  min-height: 80vh;
  padding: clamp(2rem, 4vw, 6rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: auto;
    padding: clamp(1.5rem, 3vw, 4rem) 0;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(1rem, 2vw, 2rem) 0;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.large};
  padding: 0 clamp(1rem, 2vw, 3rem);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderSection = styled(SectionWrapper)`
  position: relative;
  background: ${COLORS.background};
  animation: ${fadeIn} 1.5s ease-out;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: 70vh;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: 60vh;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const VideoBackground = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
`;

// const Logo = styled.img`
//   width: clamp(20px, 4vw, 50px);
//   margin-bottom: clamp(1rem, 2vw, 2rem);
//   margin-top: clamp(2rem, 3vw, 4rem);
//   filter: drop-shadow(0 0 6px rgba(0, 154, 205, 0.3));
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: rotate(5deg) scale(1.05);
//   }
// `;

const HeaderContent = styled.div`
  text-align: center;
  z-index: 2;
`;

const HeaderTitle = styled.h1`
  font-size: clamp(2rem, 5vw, 5rem);
  font-weight: 900;
  background: linear-gradient(45deg, ${COLORS.accent}, ${COLORS.secondary});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: clamp(1rem, 2vw, 1.8rem);

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1.5rem, 4vw, 3rem);
  }
`;

const HeaderSubtitle = styled.p`
  font-size: clamp(0.9rem, 1.5vw, 1.8rem);
  color: ${COLORS.textMuted};
  max-width: clamp(500px, 70vw, 700px);
  margin: 0 auto clamp(1.5rem, 2vw, 2.5rem);
  line-height: 1.7;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
  }
`;

const ActionButton = styled.button`
  padding: clamp(0.8rem, 1.5vw, 1.2rem) clamp(1.5rem, 2.5vw, 3rem);
  background: ${COLORS.accent};
  border: none;
  border-radius: 8px;
  font-size: clamp(1rem, 1.5vw, ${FONT_SIZES.medium});
  font-weight: 700;
  color: ${COLORS.background};
  cursor: pointer;
  transition: all 0.4s ease;
  animation: ${pulse} 2s infinite;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 154, 205, 0.5);
    animation: none;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(0.6rem, 1vw, 0.8rem) clamp(1rem, 2vw, 1.5rem);
    font-size: clamp(0.9rem, 1.2vw, 1rem);
  }
`;

const FeaturedSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1s ease-out;
`;

const CategoriesSection = styled(SectionWrapper)`
  background: rgba(20, 20, 20, 0.95);
  animation: ${slideUp} 1.2s ease-out;
`;

const TestimonialsSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.4s ease-out;
`;

const CTASection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.6s ease-out;
`;

const SectionTitle = styled.h2`
  font-size: clamp(1.8rem, 3vw, 3.5rem);
  font-weight: 800;
  text-align: center;
  background: linear-gradient(45deg, ${COLORS.accent}, ${COLORS.secondary});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: clamp(2rem, 3vw, 3.5rem);

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1.5rem, 2.5vw, 2.2rem);
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(250px, 30vw, 320px), 1fr));
  gap: clamp(1.5rem, 2vw, 3rem);
  width: 100%;
`;

const ProjectCard = styled.div`
  background: rgba(20, 20, 20, 0.95);
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.4s ease;
  box-shadow: 0 6px 20px rgba(0, 154, 205, 0.08);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 154, 205, 0.2);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: clamp(200px, 25vw, 250px);
  object-fit: cover;
`;

const ProjectContent = styled.div`
  padding: clamp(1rem, 1.5vw, 1.8rem);

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(0.8rem, 1vw, 1.2rem);
  }
`;

const ProjectTitle = styled.h3`
  font-size: clamp(1.5rem, 2vw, 1.8rem);
  color: ${COLORS.textLight};
  margin-bottom: clamp(0.5rem, 1vw, 0.8rem);
  font-weight: 700;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
  }
`;

const ProjectDescription = styled.p`
  font-size: clamp(0.9rem, 1.5vw, ${FONT_SIZES.medium});
  color: ${COLORS.textMuted};
  line-height: 1.6;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }
`;

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(220px, 25vw, 280px), 1fr));
  gap: clamp(1.5rem, 2vw, 2.5rem);
  width: 100%;
`;

const CategoryCard = styled.div`
  background: rgba(20, 20, 20, 0.95);
  padding: clamp(1rem, 1.5vw, 2rem);
  border-radius: 12px;
  text-align: center;
  transition: all 0.4s ease;
  box-shadow: 0 6px 20px rgba(0, 154, 205, 0.08);

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 25px rgba(0, 154, 205, 0.2);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(0.8rem, 1vw, 1.5rem);
  }
`;

const CategoryTitle = styled.h3`
  font-size: clamp(1.4rem, 2vw, 1.7rem);
  color: ${COLORS.textLight};
  margin-bottom: clamp(0.5rem, 1vw, 0.8rem);
  font-weight: 600;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1.2rem, 1.5vw, 1.4rem);
  }
`;

const CategoryDescription = styled.p`
  font-size: clamp(0.9rem, 1.5vw, ${FONT_SIZES.medium});
  color: ${COLORS.textMuted};

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }
`;

const TestimonialGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(250px, 30vw, 320px), 1fr));
  gap: clamp(1.5rem, 2vw, 3rem);
  width: 100%;
`;

const TestimonialCard = styled.div`
  background: rgba(20, 20, 20, 0.95);
  padding: clamp(1.5rem, 2vw, 2.5rem);
  border-radius: 16px;
  text-align: center;
  transition: all 0.4s ease;
  box-shadow: 0 6px 20px rgba(0, 154, 205, 0.08);

  &:hover {
    transform: translateY(-6px);
    box-shadow: 0 10px 25px rgba(0, 154, 205, 0.2);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(1rem, 1.5vw, 1.5rem);
  }
`;

const TestimonialText = styled.p`
  font-size: clamp(0.9rem, 1.5vw, ${FONT_SIZES.medium});
  color: ${COLORS.textLight};
  margin-bottom: clamp(0.8rem, 1vw, 1.2rem);
  line-height: 1.7;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }
`;

const TestimonialAuthor = styled.h4`
  font-size: clamp(0.9rem, 1.5vw, ${FONT_SIZES.medium});
  color: ${COLORS.accent};
  font-weight: 600;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.8rem, 1.2vw, 1rem);
  }
`;

// Components
const Header = () => (
  <HeaderSection>
    <VideoContainer>
      <VideoBackground autoPlay loop muted>
        <source src="https://cdn.pixabay.com/video/2021/09/02/87307-601148662_large.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>
    </VideoContainer>
    <ContentContainer>
      <HeaderContent>
        {/* <Logo src="favicon.png" alt="CrudOps Logo" /> */}
        <HeaderTitle>Our Work in Action</HeaderTitle>
        <HeaderSubtitle>
        See how we transform challenges into opportunities.
        </HeaderSubtitle>
        <ActionButton>Explore Projects</ActionButton>
      </HeaderContent>
    </ContentContainer>
  </HeaderSection>
);

const FeaturedProjects = () => (
  <FeaturedSection>
    <ContentContainer>
      <SectionTitle>Showcase</SectionTitle>
      <ProjectGrid>
        <ProjectCard>
          <ProjectImage src="https://site.ringme.ai/themes/front_end/images/heros/responsive1.jpg" alt="Analytics Hub" />
          <ProjectContent>
            <ProjectTitle>Ringme: Seamless Communication at Your Fingertips</ProjectTitle>
            <ProjectDescription>Ringme is your go-to solution for hassle-free video, audio, and virtual calls. With added features like QR code scanning for instant connections and secure payment options, it’s built to make communication effortless and efficient, whether for personal catch-ups or professional meetings.</ProjectDescription>
          </ProjectContent>
        </ProjectCard>
        <ProjectCard>
          <ProjectImage src="https://th.bing.com/th/id/R.d7e691ec0ba7b7a99077386e3ccdd440?rik=6gYABi0lqwtb7g&riu=http%3a%2f%2f1.bp.blogspot.com%2f-gEWxSc3aCn0%2fUEq02F73H-I%2fAAAAAAAAHcw%2femb8OUjZpH0%2fs640%2fpicframe5.jpg&ehk=r9%2boq%2fDCPAi2K%2fbu%2f9xdkzA2LVQZpQBq5G%2fvA2v441M%3d&risl=&pid=ImgRaw&r=0" alt="MarketEdge" />
          <ProjectContent>
            <ProjectTitle>PicFrames: Frame Your Memories with Style</ProjectTitle>
            <ProjectDescription>PicFrames.co.uk brings you high-quality, personalized photo frames to cherish your favorite moments. Offering a variety of styles and sizes, from sleek modern designs to classic elegance, it’s the perfect way to add a unique, personal touch to any space.</ProjectDescription>
          </ProjectContent>
        </ProjectCard>
        <ProjectCard>
          <ProjectImage src="https://static.vecteezy.com/system/resources/previews/026/481/726/large_2x/a-spectacular-gaming-adventure-with-this-stunning-4k-wallpaper-free-photo.jpg" alt="CreatorSync" />
          <ProjectContent>
            <ProjectTitle>Gaming Sijori: Gear Up for Epic Play</ProjectTitle>
            <ProjectDescription>Gaming Sijori delivers top-notch gaming PCs, accessories, and gear for every level of gamer. Whether you’re a casual player or a competitive pro, our curated setups—packed with high-performance hardware—ensure an unbeatable, immersive gaming experience.</ProjectDescription>
          </ProjectContent>
        </ProjectCard>
        {/* <ProjectCard>
          <ProjectImage src="http://www.crudops.com/img/super-work.png" alt="Super Logistics" />
          <ProjectContent>
            <ProjectTitle>Tharakcas: Your Path to the Perfect Job</ProjectTitle>
            <ProjectDescription>Tharakcas is a job portal that connects job seekers with opportunities and companies with talent. With easy searches, quick applications, and tailored recommendations, it simplifies the hiring process, helping you land your dream job or find the ideal candidate fast..</ProjectDescription>
          </ProjectContent>
        </ProjectCard> */}
        <ProjectCard>
          <ProjectImage src="https://th.bing.com/th/id/OIP.GCS91lUP1QPom9GemPxvxQHaFj?rs=1&pid=ImgDetMain" alt="David's Lad" />
          <ProjectContent>
            <ProjectTitle>David’s Lad: Streamlined Dining, Simplified Billing</ProjectTitle>
            <ProjectDescription>David’s Lad is a restaurant billing app that makes order management and payments a breeze. Designed to speed up transactions, track sales, and enhance customer service, it’s the tool every eatery needs to keep operations smooth and guests happy.</ProjectDescription>
          </ProjectContent>
        </ProjectCard>
        <ProjectCard>
          <ProjectImage src="https://www.kathakworld.com/wp-content/uploads/2020/09/aharya.jpg" alt="THARAKACAS" />
          <ProjectContent>
            <ProjectTitle>Aharya: Celebrate the Art of Dance</ProjectTitle>
            <ProjectDescription>Aharya offers a vibrant platform for arts education and Indian classical dance, from Bharatanatyam to Kathakali. Connecting enthusiasts with expert training and performances, it’s a welcoming space for beginners and seasoned artists to explore and honor tradition.</ProjectDescription>
          </ProjectContent>
        </ProjectCard>
        <ProjectCard>
          <ProjectImage src="https://static.wixstatic.com/media/9ea8e8_a0d5695b7c6e425199eeb1fa6c7e71ab~mv2.jpg/v1/fill/w_980,h_653,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9ea8e8_a0d5695b7c6e425199eeb1fa6c7e71ab~mv2.jpg" alt="THARAKACAS" />
          <ProjectContent>
            <ProjectTitle>KAD Home Interiors: Spaces Designed to Inspire</ProjectTitle>
            <ProjectDescription>KAD Home Interiors transforms homes with expert design solutions that blend style and comfort. Their website highlights a stunning portfolio and creative ideas, delivering personalized services that turn any space into an elegant, livable masterpiece.</ProjectDescription>
          </ProjectContent>
        </ProjectCard>
      </ProjectGrid>
    </ContentContainer>
  </FeaturedSection>
);

const ProjectCategories = () => (
  <CategoriesSection>
    <ContentContainer>
      <SectionTitle>Areas of Mastery</SectionTitle>
      <CategoryGrid>
        <CategoryCard>
          <CategoryTitle>Web Platforms</CategoryTitle>
          <CategoryDescription>ynamic, modern web solutions tailored for every industry.</CategoryDescription>
        </CategoryCard>
        <CategoryCard>
          <CategoryTitle>Mobile Innovation</CategoryTitle>
          <CategoryDescription>Smooth, standout apps crafted for iOS and Android.</CategoryDescription>
        </CategoryCard>
        <CategoryCard>
          <CategoryTitle>Cloud Architecture</CategoryTitle>
          <CategoryDescription>Robust, secure cloud systems that grow with you.</CategoryDescription>
        </CategoryCard>
        <CategoryCard>
          <CategoryTitle>AI Solutions</CategoryTitle>
          <CategoryDescription>Clever technology driving smarter, impactful results.</CategoryDescription>
        </CategoryCard>
      </CategoryGrid>
    </ContentContainer>
  </CategoriesSection>
);

const Testimonials = () => (
  <TestimonialsSection>
    <ContentContainer>
      <SectionTitle>Hear From Our Happy Clients</SectionTitle>
      <TestimonialGrid>
        <TestimonialCard>
          <TestimonialText> "CRUDOps helped us build a smooth and efficient communication platform. Their expertise made a big difference in our project!" </TestimonialText>
          <TestimonialAuthor>Dileep C A -Director-Ringme</TestimonialAuthor>
        </TestimonialCard>
        <TestimonialCard>
          <TestimonialText>"Our website turned out amazing! CRUDOps delivered a clean, user-friendly design that perfectly showcases our products." </TestimonialText>
          <TestimonialAuthor>PicFrames.co.uk Team</TestimonialAuthor>
        </TestimonialCard>
        <TestimonialCard>
          <TestimonialText>"Managing restaurant billing has never been this simple! CRUDOps built an intuitive and reliable solution for us." </TestimonialText>
          <TestimonialAuthor>Basil Sunny-Davids Lad -Founder</TestimonialAuthor>
        </TestimonialCard>
      </TestimonialGrid>
    </ContentContainer>
  </TestimonialsSection>
);

const CallToAction = () => (
  <CTASection>
    <ContentContainer>
      <SectionTitle>Launch Your Vision Today</SectionTitle>
      <HeaderSubtitle>
      Got a big idea? Let’s make it happen—connect with us now!
      </HeaderSubtitle>
      <ActionButton>Get Started</ActionButton>
    </ContentContainer>
  </CTASection>
);

// Main Component
function Projects() {
  return (
    <MainContainer>
      <Header />
      <FeaturedProjects />
      <ProjectCategories />
      <Testimonials />
      <CallToAction />
    </MainContainer>
  );
}

export default Projects;