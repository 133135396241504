import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Constants
const COLORS = {
  background: '#000000',
  primary: '#E0E0E0',
  secondary: '#D4A017',
  accent: '#009ACD',
  textLight: '#FFFFFF',
  textMuted: '#A0A0A0',
};

const FONT_SIZES = {
  small: '0.9rem',
  medium: '1.1rem',
  large: '1.5rem',
};

const BREAKPOINTS = {
  extraSmall: '320px',
  small: '480px',
  medium: '768px',
  large: '1200px',
  extraLarge: '1920px',
};

// Animations
const fadeIn = keyframes`
  from { opacity: 0; filter: blur(3px); }
  to { opacity: 1; filter: blur(0); }
`;

const slideUp = keyframes`
  0% { transform: translateY(40px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 154, 205, 0.5); }
  70% { box-shadow: 0 0 0 15px rgba(0, 154, 205, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 154, 205, 0); }
`;

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${COLORS.background};
  color: ${COLORS.primary};
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SectionWrapper = styled.section`
  width: 100%;
  min-height: 60vh;
  padding: clamp(1.5rem, 3vw, 4rem) 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: 50vh;
    padding: clamp(1rem, 2vw, 2.5rem) 0;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: auto;
    padding: clamp(0.8rem, 1.5vw, 1.5rem) 0;
  }

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    padding: clamp(0.5rem, 1vw, 1rem) 0;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: ${BREAKPOINTS.large};
  padding: 0 clamp(0.5rem, 1.5vw, 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${BREAKPOINTS.extraLarge}) {
    max-width: 1400px;
  }

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    padding: 0 clamp(0.3rem, 1vw, 0.5rem);
  }
`;

const HeaderSection = styled(SectionWrapper)`
  position: relative;
  background: ${COLORS.background};
  animation: ${fadeIn} 1.5s ease-out;
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: clamp(2rem, 4vw, 4rem);

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
    z-index: 0;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: 80vh;
  }

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    min-height: 70vh;
  }
`;

const HeaderContent = styled.div`
  text-align: center;
  padding: clamp(0.8rem, 1.5vw, 2rem);
  z-index: 2;

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    padding: clamp(0.5rem, 1vw, 1rem);
  }
`;

const HeaderTitle = styled.h1`
  font-size: clamp(1.5rem, 3.5vw, 4rem);
  font-weight: 900;
  background: linear-gradient(45deg, ${COLORS.accent}, ${COLORS.secondary});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: clamp(0.8rem, 1.5vw, 1.5rem);
  text-shadow: 0 3px 10px rgba(0, 154, 205, 0.3);

  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: clamp(1.2rem, 3vw, 3rem);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1rem, 2.5vw, 2rem);
  }

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    font-size: clamp(0.9rem, 2vw, 1.5rem);
  }
`;

const HeaderSubtitle = styled.p`
  font-size: clamp(0.8rem, 1.2vw, 1.4rem);
  color: ${COLORS.textMuted};
  max-width: clamp(300px, 70vw, 600px);
  margin: 0 auto clamp(0.8rem, 1.5vw, 1.5rem);
  line-height: 1.6;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(0.7rem, 1vw, 1rem);
    max-width: clamp(250px, 80vw, 400px);
  }

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    font-size: clamp(0.6rem, 0.9vw, 0.9rem);
    max-width: clamp(200px, 85vw, 300px);
  }
`;

const ActionButton = styled.button`
  padding: clamp(0.6rem, 1vw, 1rem) clamp(1rem, 2vw, 2.5rem);
  background: ${COLORS.accent};
  border: none;
  border-radius: 8px;
  font-size: clamp(0.8rem, 1vw, ${FONT_SIZES.medium});
  font-weight: 700;
  color: ${COLORS.background};
  cursor: pointer;
  transition: all 0.4s ease;
  animation: ${pulse} 2s infinite;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 154, 205, 0.5);
    animation: none;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(0.4rem, 0.8vw, 0.8rem) clamp(0.8rem, 1.5vw, 1.5rem);
    font-size: clamp(0.7rem, 0.9vw, 0.9rem);
  }

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    padding: clamp(0.3rem, 0.6vw, 0.6rem) clamp(0.6rem, 1.2vw, 1rem);
    font-size: clamp(0.6rem, 0.8vw, 0.8rem);
  }
`;

const EssenceSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${fadeIn} 1.2s ease-out;
  margin-top: clamp(2rem, 4vw, 4rem);

  @media (max-width: ${BREAKPOINTS.small}) {
    margin-top: clamp(1.5rem, 3vw, 3rem);
  }

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    margin-top: clamp(1rem, 2vw, 2rem);
  }
`;

const HistorySection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.2s ease-out;
  min-height: auto;
  padding: clamp(3rem, 4vw, 5rem) 0;

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: clamp(2rem, 3vw, 3rem) 0;
  }
`;

const CapabilitiesSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.4s ease-out;
  min-height: 100vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    min-height: 80vh;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: auto;
  }
`;

const AchievementsSection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.6s ease-out;

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: auto;
  }
`;

const CTASection = styled(SectionWrapper)`
  background: ${COLORS.background};
  animation: ${slideUp} 1.8s ease-out;

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: auto;
  }
`;

const SectionTitle = styled.h2`
  font-size: clamp(1.5rem, 2.5vw, 2.8rem);
  font-weight: 800;
  text-align: center;
  background: linear-gradient(45deg, ${COLORS.accent}, ${COLORS.secondary});
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: clamp(1rem, 1.5vw, 2rem);

  @media (max-width: ${BREAKPOINTS.medium}) {
    font-size: clamp(1.2rem, 2vw, 2rem);
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    font-size: clamp(1rem, 1.8vw, 1.6rem);
  }

  @media (max-width: ${BREAKPOINTS.extraSmall}) {
    font-size: clamp(0.9rem, 1.6vw, 1.4rem);
  }
`;

// Header Component
const Header = () => (
  <HeaderSection>
    <video autoPlay loop muted>
      <source src="https://cdn.pixabay.com/video/2023/10/01/183108-870151713_large.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <ContentContainer>
      <HeaderContent>
        <HeaderTitle> Smart Solutions for a Smarter World </HeaderTitle>
        <HeaderSubtitle>
        Building practical, thoughtful solutions to power your business and beyond.
        </HeaderSubtitle>
        <ActionButton>Explore </ActionButton>
      </HeaderContent>
    </ContentContainer>
  </HeaderSection>
);

// Essence Component
const Essence = () => {
  const EssenceContainer = styled.div`
    width: 100%;
    max-width: clamp(1000px, 95vw, 1400px); // Increased max-width
    padding: clamp(2rem, 3vw, 3rem); // Increased padding

    @media (max-width: ${BREAKPOINTS.small}) {
      max-width: clamp(700px, 90vw, 900px); // Adjusted for smaller screens
      padding: clamp(1.5rem, 2.5vw, 2.5rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      max-width: clamp(320px, 95vw, 700px); // Adjusted for extra small screens
      padding: clamp(1rem, 2vw, 2rem);
    }
  `;

  const EssenceRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: clamp(3rem, 4vw, 4rem); // Increased margin

    @media (max-width: ${BREAKPOINTS.medium}) {
      flex-direction: column;
      gap: 2.5rem; // Increased gap
      margin-bottom: clamp(2rem, 3vw, 3rem);
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      gap: 2rem; // Increased gap
      margin-bottom: clamp(1.5rem, 2.5vw, 2.5rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      gap: 1.5rem; // Increased gap
      margin-bottom: clamp(1rem, 2vw, 2rem);
    }
  `;

  const EssenceImageWrapper = styled.div`
    flex: 1;
    max-width: 48%;
    min-height: clamp(400px, 40vh, 500px); // Increased min-height
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    @media (max-width: ${BREAKPOINTS.medium}) {
      max-width: 100%;
      min-height: clamp(300px, 35vh, 400px); // Adjusted for medium screens
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      min-height: clamp(250px, 30vh, 350px); // Adjusted for small screens
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      min-height: clamp(200px, 25vh, 300px); // Adjusted for extra small screens
    }
  `;

  const EssenceImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px; // Increased border-radius
    box-shadow: 0 10px 25px rgba(0, 154, 205, 0.1); // Increased shadow
  `;

  const EssenceTextBlock = styled.div`
    flex: 1;
    max-width: 48%;
    min-height: clamp(400px, 40vh, 500px); // Increased min-height
    background: rgba(20, 20, 20, 0.95);
    border-radius: 20px; // Increased border-radius
    padding: clamp(2rem, 2.5vw, 2.5rem); // Increased padding
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      transform: translateY(-5px);
    }

    @media (max-width: ${BREAKPOINTS.medium}) {
      max-width: 100%;
      min-height: clamp(300px, 35vh, 400px); // Adjusted for medium screens
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      min-height: clamp(250px, 30vh, 350px); // Adjusted for small screens
      padding: clamp(1.5rem, 2vw, 2rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      min-height: clamp(200px, 25vh, 300px); // Adjusted for extra small screens
      padding: clamp(1rem, 1.5vw, 1.5rem);
    }
  `;

  const EssenceTitle = styled.h3`
    font-size: clamp(1.8rem, 2.5vw, 2.5rem); // Increased font size
    color: ${COLORS.textLight};
    margin-bottom: clamp(1rem, 1.2vw, 1.2rem); // Increased margin
    font-weight: 700;

    @media (max-width: ${BREAKPOINTS.small}) {
      font-size: clamp(1.5rem, 2vw, 2rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      font-size: clamp(1.2rem, 1.8vw, 1.6rem);
    }
  `;

  const EssenceDescription = styled.p`
    font-size: clamp(1rem, 1.3vw, 1.2rem); // Increased font size
    color: ${COLORS.textMuted};
    line-height: 1.6;

    @media (max-width: ${BREAKPOINTS.small}) {
      font-size: clamp(0.9rem, 1.1vw, 1rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      font-size: clamp(0.8rem, 1vw, 0.9rem);
    }
  `;

  return (
    <EssenceSection>
      <ContentContainer>
        <SectionTitle>Our Essence</SectionTitle>
        <EssenceContainer>
          <EssenceRow>
            <EssenceImageWrapper>
              <EssenceImage
                src="https://woz-u.com/wp-content/uploads/2019/11/software-developer-skills.jpeg"
                alt="CrudOps Logo"
              />
            </EssenceImageWrapper>
            <EssenceTextBlock>
              <EssenceTitle>Our Vision & Mission</EssenceTitle>
              <EssenceDescription>
              At CRUDOps, our vision is to lead in providing advanced AI solutions that transform industries and improve business outcomes. Our mission is to deliver reliable, intelligent solutions that enhance efficiency and drive value, while prioritizing customer satisfaction at every stage.
              </EssenceDescription>
            </EssenceTextBlock>
          </EssenceRow>
          <EssenceRow>
            <EssenceTextBlock>
              <EssenceTitle>Why Choose Us</EssenceTitle>
              <EssenceDescription>
              We’re more than just a tech provider—we’re your partner in progress. With a knack for turning complex challenges into simple, effective solutions, our team brings expertise, dedication, and a personal touch to every project. We listen closely, tailor our work to fit your unique needs, and deliver results that don’t just meet expectations but exceed them. 
              </EssenceDescription>
            </EssenceTextBlock>
            <EssenceImageWrapper>
              <EssenceImage
                src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80"
                alt="Why Choose Us"
              />
            </EssenceImageWrapper>
          </EssenceRow>
          <EssenceRow>
            <EssenceTextBlock>
              <EssenceTitle>Our Values</EssenceTitle>
              <EssenceDescription>
              We live by innovation, reliability, and a deep focus on our customers. We believe in creating solutions that not only solve problems but also inspire progress, built on a foundation of trust and quality. Every step we take is guided by a commitment to excellence, ensuring we deliver technology that empowers your business with purpose and precision.
              </EssenceDescription>
            </EssenceTextBlock>
            <EssenceImageWrapper>
              <EssenceImage
                src="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80"
                alt="Our Values"
              />
            </EssenceImageWrapper>
          </EssenceRow>
          <EssenceRow>
            <EssenceImageWrapper>
              <EssenceImage
                src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80"
                alt="Our Approach"
              />
            </EssenceImageWrapper>
            <EssenceTextBlock>
              <EssenceTitle>Our Approach</EssenceTitle>
              <EssenceDescription>
              We focus on understanding our clients' unique challenges and delivering AI-powered solutions tailored to their needs. By combining innovation with practical implementation, we ensure that our solutions are not only effective but also provide lasting value and an excellent customer experience.
              </EssenceDescription>
            </EssenceTextBlock>
          </EssenceRow>
        </EssenceContainer>
      </ContentContainer>
    </EssenceSection>
  );
};

// History Component

const History = () => {
  const timelineItems = [
    {
      year: "2023",
      title: "A Humble Beginning",
      description: "Crudops Private Limited started with a simple idea—to make technology work for businesses in a real, meaningful way. Born from a small team with big dreams, we set out to craft solutions that solve everyday challenges while sparking growth. From those modest roots, we’ve grown into a trusted partner, staying true to our mission of delivering practical, impactful tech with a personal touch.",
      image: "https://woz-u.com/wp-content/uploads/2019/11/software-developer-skills.jpeg",
    },
    {
      year: "2024",
      title: "A Year of Transformation and Global Reach",
      description: "In a pivotal year, Crudops Private Limited took bold steps to redefine itself, embracing new technologies and expanding its horizons beyond borders. ",
      image: "https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80",
    },
    {
      year: "2025",
      title: "Scaling New Heights",
      description: "We’ve climbed to new peaks by pushing the boundaries of what’s possible. With each project, we’ve honed our skills, embraced bolder challenges, and delivered solutions that elevate businesses to greater success.",
      image: "https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-4.0.3&auto=format&fit=crop&w=1350&q=80",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? timelineItems.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === timelineItems.length - 1 ? 0 : prev + 1));
  };

  const HistorySection = styled.section`
    background: ${COLORS.background};
    padding: clamp(4rem, 6vw, 6rem) 0; // Increased padding for more vertical space
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${slideUp} 1.2s ease-out;
  `;

  const ContentContainer = styled.div`
    width: 100%;
    padding: 0 clamp(2rem, 4vw, 4rem); // Increased padding for more horizontal space
    box-sizing: border-box;
  `;

  const SectionTitle = styled.h2`
    font-size: clamp(2rem, 3.5vw, 4rem); // Increased font size
    font-weight: 800;
    text-align: center;
    background: linear-gradient(45deg, ${COLORS.accent}, ${COLORS.secondary});
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: clamp(1.5rem, 2vw, 2.5rem); // Increased margin

    @media (max-width: ${BREAKPOINTS.medium}) {
      font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      font-size: clamp(1.2rem, 2vw, 2rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      font-size: clamp(1rem, 1.8vw, 1.6rem);
    }
  `;

  const TimelineHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: clamp(1.5rem, 2.5vw, 2.5rem); // Increased margin
  `;

  const Timeline = styled.div`
    display: flex;
    align-items: center;
    gap: clamp(1.5rem, 2.5vw, 2.5rem); // Increased gap between timeline items
  `;

  const YearDot = styled.div`
    width: 16px; // Increased size
    height: 16px; // Increased size
    background: ${(props) => (props.active ? COLORS.accent : COLORS.textMuted)};
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: ${COLORS.accent};
    }
  `;

  const TimelineLine = styled.div`
    flex: 1;
    height: 3px; // Increased thickness
    background: ${COLORS.textMuted};
  `;

  const YearLabel = styled.span`
    font-size: clamp(1rem, 1.5vw, 1.5rem); // Increased font size
    color: ${COLORS.textLight};
    margin: 0 0.75rem; // Increased margin
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: ${COLORS.accent};
    }
  `;

  const HistoryContent = styled.div`
    display: flex;
    align-items: center;
    gap: clamp(3rem, 4vw, 4rem); // Increased gap between image and text
    flex-wrap: wrap;
    justify-content: center;
  `;

  const ImageContainer = styled.div`
    flex: 1;
    min-width: 400px; // Increased min-width
    max-width: 600px; // Increased max-width
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  `;

  const HistoryImage = styled.img`
    width: 100%;
    height: 400px; // Increased height
    object-fit: cover;
    border-radius: 20px; // Slightly increased border-radius
    box-shadow: 0 10px 25px rgba(0, 154, 205, 0.1); // Increased shadow
    filter: grayscale(100%);
    transition: filter 0.3s ease;

    &:hover {
      filter: grayscale(0%);
    }

    @media (max-width: ${BREAKPOINTS.medium}) {
      height: 300px; // Adjusted for medium screens
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      height: 250px; // Adjusted for small screens
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      height: 200px; // Adjusted for extra small screens
    }
  `;

  const TextContainer = styled.div`
    flex: 1;
    min-width: 400px; // Increased min-width
    max-width: 600px; // Increased max-width
    padding: clamp(2rem, 2.5vw, 2.5rem); // Increased padding
    background: rgba(20, 20, 20, 0.95);
    border-radius: 20px; // Slightly increased border-radius
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover {
      transform: translateY(-5px);
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      padding: clamp(1.5rem, 2vw, 2rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      padding: clamp(1rem, 1.5vw, 1.5rem);
    }
  `;

  const CardTitle = styled.h4`
    font-size: clamp(1.8rem, 2.5vw, 2.5rem); // Increased font size
    color: ${COLORS.textLight};
    margin-bottom: clamp(1rem, 1.2vw, 1.2rem); // Increased margin
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: ${BREAKPOINTS.small}) {
      font-size: clamp(1.5rem, 2vw, 2rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      font-size: clamp(1.2rem, 1.8vw, 1.6rem);
    }
  `;

  const CardDescription = styled.p`
    font-size: clamp(1rem, 1.3vw, 1.2rem); // Increased font size
    color: ${COLORS.textMuted};
    line-height: 1.6;
    margin-bottom: clamp(1.5rem, 2vw, 2rem); // Increased margin

    @media (max-width: ${BREAKPOINTS.small}) {
      font-size: clamp(0.9rem, 1.1vw, 1rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      font-size: clamp(0.8rem, 1vw, 0.9rem);
    }
  `;

  const Navigation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem; // Increased gap
  `;

  const NavButton = styled.button`
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 50%;
    width: 50px; // Increased size
    height: 50px; // Increased size
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${COLORS.textLight};
    font-size: 1.5rem; // Increased font size
    transition: background 0.3s ease;

    &:hover {
      background: ${COLORS.accent};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  return (
    <HistorySection>
      <ContentContainer>
        <SectionTitle>CrudOps Journey</SectionTitle>
        <TimelineHeader>
          <Timeline>
            {timelineItems.map((item, index) => (
              <React.Fragment key={index}>
                <YearLabel onClick={() => setCurrentIndex(index)}>
                  {item.year}
                </YearLabel>
                <YearDot active={index === currentIndex} onClick={() => setCurrentIndex(index)} />
                {index < timelineItems.length - 1 && <TimelineLine />}
              </React.Fragment>
            ))}
          </Timeline>
        </TimelineHeader>
        <HistoryContent>
          <ImageContainer>
            <HistoryImage src={timelineItems[currentIndex].image} alt={timelineItems[currentIndex].title} />
          </ImageContainer>
          <TextContainer>
            <CardTitle>{timelineItems[currentIndex].title}</CardTitle>
            <CardDescription>{timelineItems[currentIndex].description}</CardDescription>
            <Navigation>
              <NavButton onClick={handlePrev} disabled={currentIndex === 0}>←</NavButton>
              <NavButton onClick={handleNext} disabled={currentIndex === timelineItems.length - 1}>→</NavButton>
            </Navigation>
          </TextContainer>
        </HistoryContent>
      </ContentContainer>
    </HistorySection>
  );
};

// Capabilities Component
const Capabilities = () => {
  const capabilitiesData = [
    {
      level: '01',
      title: 'Initial',
      description: 'Work is unpredictable, often delayed, and exceeds budget constraints. This stage represents the starting point where processes are ad-hoc and reactive, lacking formal structure.',
    },
    {
      level: '02',
      title: 'Managed',
      description: 'Projects are systematically planned, tracked, and controlled. Teams begin to establish repeatable processes, though consistency across the organization may still be limited.',
    },
    {
      level: '03',
      title: 'Defined',
      description: 'Proactive standards guide projects, programs, and portfolios. The organization adopts a unified approach, ensuring consistency and predictability in outcomes.',
    },
    {
      level: '04',
      title: 'Quantitatively Managed',
      description: 'Quantitative goals drive predictable performance improvements. Data and metrics become central to decision-making, aligning efforts with stakeholder needs.',
    },
    {
      level: '05',
      title: 'Optimizing',
      description: 'Continuous improvement fuels agility and innovation. The organization is stable yet flexible, ready to pivot and capitalize on opportunities as they arise.',
    },
  ];

  const CapabilitiesSection = styled.section`
    background: ${COLORS.background};
    padding: clamp(3rem, 5vw, 5rem) 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${slideUp} 1.4s ease-out;

    @media (max-width: ${BREAKPOINTS.medium}) {
      min-height: 80vh;
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      min-height: auto;
    }
  `;

  const ContentContainer = styled.div`
    width: 100%;
    padding: 0 clamp(1rem, 3vw, 3rem);
    box-sizing: border-box;
  `;

  const SectionTitle = styled.h2`
    font-size: clamp(1.5rem, 2.5vw, 2.8rem);
    font-weight: 800;
    text-align: center;
    background: linear-gradient(45deg, ${COLORS.accent}, ${COLORS.secondary});
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: clamp(1rem, 1.5vw, 2rem);

    @media (max-width: ${BREAKPOINTS.medium}) {
      font-size: clamp(1.2rem, 2vw, 2rem);
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      font-size: clamp(1rem, 1.8vw, 1.6rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      font-size: clamp(0.9rem, 1.6vw, 1.4rem);
    }
  `;

  const CapabilitiesContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: clamp(1rem, 2vw, 2rem);
    flex-wrap: wrap;

    @media (max-width: ${BREAKPOINTS.medium}) {
      justify-content: center;
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      flex-direction: column;
      align-items: center;
    }
  `;

  const CapabilityCard = styled.div`
    flex: 1;
    min-width: 200px;
    max-width: 250px;
    padding: clamp(1rem, 2vw, 1.5rem);
    background: rgba(20, 20, 20, 0.95);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease, opacity 0.3s ease;

    &:hover {
      transform: translateY(-10px);
      opacity: 0.9;
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      max-width: 100%;
      text-align: center;
    }
  `;

  const CardLevel = styled.div`
    font-size: clamp(1.5rem, 3vw, 2rem);
    font-weight: 800;
    color: ${COLORS.textLight};
    margin-bottom: 0.5rem;
    opacity: 0.7;
  `;

  const CardTitle = styled.h3`
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    font-weight: 600;
    color: ${COLORS.textLight};
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 50px;
      height: 2px;
      background: linear-gradient(to right, ${COLORS.accent}, ${COLORS.secondary});
      transition: width 0.3s ease;
    }

    ${CapabilityCard}:hover &:after {
      width: 80px;
    }

    @media (max-width: ${BREAKPOINTS.small}) {
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  `;

  const CardDescription = styled.p`
    font-size: clamp(0.8rem, 1.2vw, 1rem);
    color: ${COLORS.textMuted};
    line-height: 1.6;
  `;

  // return (
  //   <CapabilitiesSection>
  //     <ContentContainer>
  //       <SectionTitle>Our Process</SectionTitle>
  //       <CapabilitiesContainer>
  //         {capabilitiesData.map((item, index) => (
  //           <CapabilityCard key={index}>
  //             <CardLevel>{item.level}</CardLevel>
  //             <CardTitle>{item.title}</CardTitle>
  //             <CardDescription>{item.description}</CardDescription>
  //           </CapabilityCard>
  //         ))}
  //       </CapabilitiesContainer>
  //     </ContentContainer>
  //   </CapabilitiesSection>
  // );
};

// Achievements Component
const Achievements = () => {
  const AchievementsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(clamp(150px, 25vw, 200px), 1fr));
    gap: clamp(1rem, 1.5vw, 2rem);
    width: 100%;
    padding: 0 clamp(0.5rem, 1vw, 1rem);

    @media (max-width: ${BREAKPOINTS.small}) {
      grid-template-columns: repeat(auto-fit, minmax(clamp(120px, 40vw, 150px), 1fr));
      gap: clamp(0.8rem, 1.2vw, 1.2rem);
      padding: 0 clamp(0.3rem, 0.8vw, 0.8rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      grid-template-columns: 1fr;
      gap: clamp(0.5rem, 1vw, 1rem);
      padding: 0 clamp(0.2rem, 0.5vw, 0.5rem);
    }
  `;

  const AchievementCard = styled.div`
    background: rgba(20, 20, 20, 0.95);
    padding: clamp(0.8rem, 1.5vw, 1.5rem);
    border-radius: 12px;
    text-align: center;
    border: 1px solid rgba(0, 154, 205, 0.15);
    transition: all 0.4s ease;

    @media (max-width: ${BREAKPOINTS.small}) {
      padding: clamp(0.6rem, 1.2vw, 1.2rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      padding: clamp(0.5rem, 1vw, 1rem);
    }
  `;

  const AchievementNumber = styled.h3`
    color: ${COLORS.secondary};
    font-size: clamp(1.5rem, 2vw, 2rem);
    font-weight: 800;
    margin-bottom: clamp(0.4rem, 0.8vw, 0.8rem);

    @media (max-width: ${BREAKPOINTS.small}) {
      font-size: clamp(1.2rem, 1.8vw, 1.8rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      font-size: clamp(1rem, 1.6vw, 1.6rem);
    }
  `;

  const AchievementText = styled.p`
    color: ${COLORS.textMuted};
    font-size: clamp(0.8rem, 1vw, ${FONT_SIZES.medium});

    @media (max-width: ${BREAKPOINTS.small}) {
      font-size: clamp(0.7rem, 0.9vw, 0.9rem);
    }

    @media (max-width: ${BREAKPOINTS.extraSmall}) {
      font-size: clamp(0.6rem, 0.8vw, 0.8rem);
    }
  `;

  return (
    <AchievementsSection>
      <ContentContainer>
        <SectionTitle>Our Triumphs</SectionTitle>
        <AchievementsGrid>
          <AchievementCard>
            <AchievementNumber>250+</AchievementNumber>
            <AchievementText>Innovations Launched</AchievementText>
          </AchievementCard>
          <AchievementCard>
            <AchievementNumber>95%</AchievementNumber>
            <AchievementText>Client Retention</AchievementText>
          </AchievementCard>
          <AchievementCard>
            <AchievementNumber>30+</AchievementNumber>
            <AchievementText>Global Partners</AchievementText>
          </AchievementCard>
          <AchievementCard>
            <AchievementNumber>1M+</AchievementNumber>
            <AchievementText>Users Impacted</AchievementText>
          </AchievementCard>
        </AchievementsGrid>
      </ContentContainer>
    </AchievementsSection>
  );
};

// CallToAction Component
const CallToAction = () => (
  <CTASection>
    <ContentContainer>
      <SectionTitle>Lead the Future with CRUDOps!</SectionTitle>
      <HeaderSubtitle>
      Partner with CRUDOps to revolutionize technology and drive innovation. Let’s shape the future together!
      </HeaderSubtitle>
      <ActionButton>Connect Now</ActionButton>
    </ContentContainer>
  </CTASection>
);

// Main Component
function About() {
  return (
    <MainContainer>
      <Header />
      <Essence />
      <Capabilities />
      <History />
      <Achievements />
      <CallToAction />
    </MainContainer>
  );
}

export default About;