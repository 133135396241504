import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/showcase.css';

const Showcase = () => {
  const navigate = useNavigate();
  const gridRef = useRef(null);
  const showcaseRefs = useRef([]);
  const autoScrollRef = useRef(null);

  useEffect(() => {
    const observers = showcaseRefs.current.map((card) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            card.classList.add('pop-up');
          }
        },
        { threshold: 0.3 }
      );
      if (card) observer.observe(card);
      return observer;
    });

    const scrollContainer = gridRef.current;
    let scrollDirection = 1;
    const scrollInterval = 2000;

    const autoScroll = () => {
      if (scrollContainer) {
        const maxScroll = scrollContainer.scrollWidth - scrollContainer.clientWidth;

        if (scrollContainer.scrollLeft >= maxScroll) {
          scrollDirection = -1;
        } else if (scrollContainer.scrollLeft <= 0) {
          scrollDirection = 1;
        }

        const adjustedScrollStep =
          window.innerWidth <= 400 ? 250 : window.innerWidth <= 800 ? 300 : 350;

        scrollContainer.scrollBy({
          left: scrollDirection * adjustedScrollStep,
          behavior: 'smooth',
        });
      }
    };

    autoScrollRef.current = setInterval(autoScroll, scrollInterval);

    // Pause auto-scroll on hover
    const handleMouseEnter = () => clearInterval(autoScrollRef.current);
    const handleMouseLeave = () => {
      autoScrollRef.current = setInterval(autoScroll, scrollInterval);
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('mouseenter', handleMouseEnter);
      scrollContainer.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      observers.forEach((observer) => observer.disconnect());
      if (autoScrollRef.current) {
        clearInterval(autoScrollRef.current);
      }
      if (scrollContainer) {
        scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
        scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  const scrollLeft = () => {
    if (gridRef.current) {
      const scrollAmount =
        window.innerWidth <= 400 ? 250 : window.innerWidth <= 800 ? 300 : 350;
      gridRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (gridRef.current) {
      const scrollAmount =
        window.innerWidth <= 400 ? 250 : window.innerWidth <= 800 ? 300 : 350;
      gridRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleCardClick = (id) => {
    navigate(`/projects/${id}`); // Updated to include project ID in the route
  };

  const projects = [
    {
      id: 1,
      title: 'Ringme',
      description: 'Ringme helps you make video, audio, and virtual calls without any hassle. It also supports QR code scanning and secure payments, making communication smooth and efficient.',
      image:
        'https://site.ringme.ai/themes/front_end/images/heros/responsive1.jpg',
    },
    {
      id: 2,
      title: 'PicFrames',
      description: 'PicFrames.co.uk offers high-quality, personalized photo frames to showcase your favorite memories. ',
      image:
        'https://th.bing.com/th/id/R.d7e691ec0ba7b7a99077386e3ccdd440?rik=6gYABi0lqwtb7g&riu=http%3a%2f%2f1.bp.blogspot.com%2f-gEWxSc3aCn0%2fUEq02F73H-I%2fAAAAAAAAHcw%2femb8OUjZpH0%2fs640%2fpicframe5.jpg&ehk=r9%2boq%2fDCPAi2K%2fbu%2f9xdkzA2LVQZpQBq5G%2fvA2v441M%3d&risl=&pid=ImgRaw&r=0',
    },
    {
      id: 3,
      title: 'Gaming Sijori',
      description: 'Gaming Sijori brings top-quality gaming PCs, accessories, and gear for all levels of gamers. ',
      image:
        'https://static.vecteezy.com/system/resources/previews/026/481/726/large_2x/a-spectacular-gaming-adventure-with-this-stunning-4k-wallpaper-free-photo.jpg',
    },
    // {
    //   id: 4,
    //   title: 'Tharakcas',
    //   description: 'Tharakcas is a job portal that connects job seekers with the right opportunities and helps companies find the best talent. ',
    //   image:
    //     'https://img.freepik.com/free-photo/html-css-collage-concept-with-person_23-2150061967.jpg?t=st=1742620512~exp=1742624112~hmac=0e6f93ef31a9610fdd5282819d5c4cdead996547b2b9a363e7ea0d3695d25704&w=1380',
    // },
    {
      id: 5,
      title: 'David’s Lad',
      description: 'David’s Lad is a restaurant billing application that simplifies order management and payments. ',
      image:
        'https://th.bing.com/th/id/OIP.GCS91lUP1QPom9GemPxvxQHaFj?rs=1&pid=ImgDetMain',
    },
    {
      id: 6,
      title: 'Aharya',
      description: 'Aharya offers a vibrant platform for arts education and Indian classical dance, connecting enthusiasts with expert training and performances. ',
      image:
        'https://www.kathakworld.com/wp-content/uploads/2020/09/aharya.jpg',
    },
    {
      id: 7,
      title: 'KAD Home Interiors – Designing Spaces, Creating Comfort',
      description: 'KAD Home Interiors offers expert interior design solutions to transform homes with style and elegance. ',
      image:
        'https://static.wixstatic.com/media/9ea8e8_a0d5695b7c6e425199eeb1fa6c7e71ab~mv2.jpg/v1/fill/w_980,h_653,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/9ea8e8_a0d5695b7c6e425199eeb1fa6c7e71ab~mv2.jpg',
    },
  ];

  return (
    <section className="showcase-section">
      <div className="content-container">
        <h2 className="section-title">Discover Our Masterpieces of Innovation</h2>
        <div className="grid-wrapper">
          <div className="feature-grid" ref={gridRef}>
            {projects.map((project, index) => (
              <div
                key={project.id}
                className="showcase-card"
                ref={(el) => (showcaseRefs.current[index] = el)}
                onClick={() => handleCardClick(project.id)}
                style={{ '--index': index }}
              >
                <div className="image-container">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="showcase-image"
                    onError={(e) => (e.target.src = 'https://via.placeholder.com/400x280')} // Fallback image
                  />
                  <div className="image-overlay">
                    <h3 className="overlay-title">{project.title}</h3>
                  </div>
                </div>
                <div className="text-container">
                  <h3 className="feature-title">{project.title}</h3>
                  <p className="feature-description">{project.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="arrow-container">
            <button className="arrow-button left-arrow" onClick={scrollLeft}>
              <span>←</span>
            </button>
            <button className="arrow-button right-arrow" onClick={scrollRight}>
              <span>→</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Showcase;