import React from 'react';
import '../style/hero.css';

const Hero = () => {
  return (
    <section className="hero-section">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="hero-video"
      >
        <source src="https://cdn.pixabay.com/video/2023/11/21/190002-887067381_large.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content-container">
        <h1 className="hero-title">
          {/* <span style={{ color: '#FFFFFF' }}>Strategic AI Implementation:  </span> */}
          <span style={{ color: '#D6B11D' }}>CrudOps</span><span style={{ color: '#FFFFFF' }}>Realizes Your Industry Innovation.</span>
        </h1>
        <p className="hero-subtitle">Innovative IT solutions and digital transformation services.</p>
        <button className="action-button">Get Started</button>
      </div>
    </section>
  );
};

export default Hero;