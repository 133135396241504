import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/features.css';

const Features = () => {
  const featureRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            entry.target.style.animationDelay = `${index * 0.25}s`;
            entry.target.classList.remove('fade-out');
            entry.target.classList.add('fade-in');
          } else {
            entry.target.classList.remove('fade-in');
            entry.target.classList.add('fade-out');
          }
        });
      },
      { threshold: 0.4 }
    );

    featureRefs.current.forEach((ref) => ref && observer.observe(ref));
    return () => observer.disconnect();
  }, []);

  const features = [
    {
      id: 'custom-software-development',
      subtitle: 'Our Services',
      title: 'Custom Software Development',
      description: {
        general: 'Discover our tailored solutions, designed to meet your unique business needs',
        specific: 'Need software that fits your business like a glove? We design and build tailored software solutions to streamline operations, boost productivity, and meet your unique goals.',
      },
      image: 'https://img.freepik.com/free-photo/top-view-unrecognizable-hacker-performing-cyberattack-night_1098-18706.jpg?t=st=1742548091~exp=1742551691~hmac=e3b5f74aa2fe0f8478ca6f3b8ff4591fe7b9215150431d08dcaf9d3b0dd0198f&w=740',
    
  },
    {
      id: 'web-development',
      subtitle: 'Our Services',
      title: 'Web Development',
      description: {
        general: 'Explore our comprehensive services, designed to propel your business forward',
        specific: 'Make a lasting impression online with stunning, responsive websites. We build fast, secure, and user-friendly web experiences tailored to your vision.',
      },
      image: 'https://img.freepik.com/premium-photo/female-project-supervisor-explaining-detail-project-developing-website-design-coding-technologies-creative-office_35674-16077.jpg?w=740',
    },
    {
      id: 'mobile-app-development',
      subtitle: 'Our Services',
      title: 'Mobile App Development',
      description: {
        general: 'Discover our innovative solutions, crafted to enhance your digital presence',
        specific: 'Reach your audience anywhere, anytime. Our team crafts intuitive, high-performance mobile apps for iOS and Android that engage users and elevate your brand.',
      },
      image: 'https://img.freepik.com/free-photo/representations-user-experience-interface-design_23-2150038900.jpg?t=st=1742548240~exp=1742551840~hmac=f8ec33f2e6e36c4c675d513c6ecbe4e2be7fbee4c2d8a2b529eb0262f7b5fa97&w=740',
    },
    {
      id: 'erp-development',
      subtitle: 'Our Services',
      title: 'ERP Development',
      description: {
        general: 'Unlock the potential of your business with our tailored ERP solutions',
        specific: 'Simplify complexity with our custom ERP solutions. We integrate your business processes—finance, HR, supply chain, and more—into one seamless, efficient system.',
      },
      image: 'https://img.freepik.com/free-photo/digital-environment-scene_23-2151873106.jpg?t=st=1742549169~exp=1742552769~hmac=52af86777fb86040fdfa234ee699fc3b45ed0d843bba2e23a61b1248f96334ae&w=740',
    },
  ];

  const handleReadMore = (serviceId) => {
    navigate(`/services?service=${serviceId}`);
  };

  return (
    <section className="feature-section">
      <div className="gradient-overlay" />
      <div className="content-container">
        {features.map((feature, index) => (
          <div
            key={index}
            ref={(el) => (featureRefs.current[index] = el)}
            className="feature-highlight-section fade-out"
          >
            <img
              src={feature.image}
              alt={feature.title}
              className="feature-highlight-image"
            />
            <div className="feature-highlight-content">
              <h2 className="feature-highlight-title subtitle">{feature.subtitle}</h2>
              <h2 className="feature-highlight-title">{feature.title}</h2>
              <p className="feature-highlight-description">
                {feature.description.general}
              </p>
              <p className="feature-highlight-description">
                {feature.description.specific}
              </p>
              <button
                className="feature-highlight-button"
                onClick={() => handleReadMore(feature.id)}
              >
                Read More
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;