import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Gold color scheme
const goldColors = {
  accent: '#FFD700',
  hoverAccent: '#FFC000',
  shadow: 'rgba(255, 215, 0, 0.4)',
  textLight: '#FFFFFF',
  background: 'transparent',
  scrolledBackground: '#000', // Background color when scrolled
};

const Nav = styled.nav`
  width: 100%;
  position: fixed;
  height: 100px;
  top: 0;
  left: 0;
  z-index: 1000;
  background: ${(props) => (props.scrolled ? goldColors.scrolledBackground : goldColors.background)};
  transition: background-color 0.3s ease-in-out;
`;

const NavContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0.5rem clamp(1rem, 2vw, 2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const Logo = styled.img`
  width: clamp(100px, 10vw, 80px);
  margin: 0;
  filter: drop-shadow(0 0 10px rgba(7, 135, 112, 0.4));
  transition: transform 0.3s ease;
  &:hover {
    transform: rotate(5deg) scale(1.1);
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: clamp(1rem, 2vw, 2rem);

  @media (max-width: 768px) {
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: rgba(4, 0, 39, 0.9);
    padding: 1rem;
    box-shadow: 0 4px 15px ${goldColors.shadow};
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${goldColors.textLight};
  text-decoration: none;
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  font-weight: 600;
  padding: 0.5rem 0;
  transition: all 0.3s ease;

  &:hover {
    color: ${goldColors.hoverAccent};
    text-shadow: 0 0 5px ${goldColors.shadow};
  }

  &.active {
    color: ${goldColors.accent};
    border-bottom: 2px solid ${goldColors.accent};
  }

  @media (max-width: 768px) {
    padding: 0.8rem 1rem;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: ${goldColors.accent};
  font-size: clamp(1.2rem, 3vw, 1.8rem);
  cursor: pointer;
  padding: 0.5rem;

  @media (max-width: 768px) {
    display: block;
  }
`;

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Nav scrolled={scrolled}>
      <NavContent>
        <Logo src="/logo-white.png" alt="crudops Logo" />
        <MenuButton onClick={() => setIsOpen(!isOpen)}>☰</MenuButton>
        <NavLinks isOpen={isOpen}>
          <StyledNavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
            Home
          </StyledNavLink>
          <StyledNavLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>
            About
          </StyledNavLink>
          <StyledNavLink to="/services" className={({ isActive }) => (isActive ? 'active' : '')}>
            Services
          </StyledNavLink>
          <StyledNavLink to="/projects" className={({ isActive }) => (isActive ? 'active' : '')}>
            Projects
          </StyledNavLink>
          <StyledNavLink to="/contact" className={({ isActive }) => (isActive ? 'active' : '')}>
            Contact
          </StyledNavLink>
        </NavLinks>
      </NavContent>
    </Nav>
  );
}

export default Navbar;
