import React from 'react';
import Cursor from '../components/CursorComponent'
import Hero from '../components/HeroComponent';
// import Services from '../components/ServiceComponent';
import Features from '../components/FeaturesComponent';
import AboutUs from '../components/AboutUsComponent';
import Showcase from '../components/ShowcaseComponent';
import Testimonials from '../components/TestimonialsComponent';
import Blog from '../components/BlogComponent';
import CaseStudies from '../components/CaseStudiesComponent';
import Career from '../components/CareerComponent';
import Contact from '../components/ContactComponent';
import { MainContainer } from '../style/styles';
import Clients from '../components/ClientComponent';

const Home = () => {
  return (
    <>
    {/* <Cursor /> */}
    <MainContainer>
      <Hero />
      <Features />
      {/* <AboutUs /> */}
      <CaseStudies />
      <Showcase />
      <Career />
      <Testimonials />
      {/* <Clients /> */}
      {/* <Blog /> */}
      <Contact />
    </MainContainer>
    </>
  );
};

export default Home;