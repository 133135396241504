import React, { useRef, useEffect } from 'react';
import $ from "jquery";
import '../style/aboutus.css';

const AboutUs = () => {
  const aboutRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            entry.target.style.animationDelay = `${index * 0.25}s`;
            entry.target.classList.remove('fade-out');
            entry.target.classList.add('fade-in');
          } else {
            entry.target.classList.remove('fade-in');
            entry.target.classList.add('fade-out');
          }
        });
      },
      { threshold: 0.4 }
    );

    aboutRef.current.forEach((ref) => ref && observer.observe(ref));

    // jQuery Circular Menu Script
    let i = 1;
    var radius = 300;
    var fields = $(".itemDot");
    var container = $(".dotCircle");
    var width = container.width();
    radius = width / 2.5;

    var height = container.height();
    var angle = 0,
      step = (2 * Math.PI) / fields.length;

    fields.each(function () {
      var x = Math.round(width / 2 + radius * Math.cos(angle) - $(this).width() / 2);
      var y = Math.round(height / 2 + radius * Math.sin(angle) - $(this).height() / 2);
      $(this).css({ left: x + "px", top: y + "px" });
      angle += step;
    });

    $(".itemDot").click(function () {
      var dataTab = $(this).data("tab");
      $(".itemDot").removeClass("active");
      $(this).addClass("active");
      $(".CirItem").removeClass("active");
      $(".CirItem" + dataTab).addClass("active");

      i = dataTab;

      $(".dotCircle").css({
        transform: "rotate(" + (360 - (i - 1) * (360 / fields.length)) + "deg)",
        transition: "2s",
      });

      $(".itemDot").css({
        transform: "rotate(" + (i - 1) * (360 / fields.length) + "deg)",
        transition: "1s",
      });
    });

    const interval = setInterval(() => {
      var dataTab = $(".itemDot.active").data("tab");
      if (dataTab >= fields.length || i > fields.length) {
        dataTab = 1;
        i = 1;
      }

      $(".itemDot").removeClass("active");
      $('[data-tab="' + i + '"]').addClass("active");
      $(".CirItem").removeClass("active");
      $(".CirItem" + i).addClass("active");
      i++;

      $(".dotCircle").css({
        transform: "rotate(" + (360 - (i - 2) * (360 / fields.length)) + "deg)",
        transition: "2s",
      });

      $(".itemDot").css({
        transform: "rotate(" + (i - 2) * (360 / fields.length) + "deg)",
        transition: "1s",
      });
    }, 5000);

    return () => {
      observer.disconnect();
      $(".itemDot").off("click");
      clearInterval(interval);
    };
  }, []);

  const aboutContent = [
    {
      id: 1,
      title: 'Who We Are',
      description: 'We are a passionate team of innovators, developers, and strategists dedicated to crafting cutting-edge digital solutions.',
      icon: 'fa fa-users',
    },
    {
      id: 2,
      title: 'Our Vision',
      description: 'To create innovative and user-friendly digital solutions that empower businesses.',
      icon: 'fa fa-eye',
    },
    {
      id: 3,
      title: 'Our Mission',
      description: 'Our mission is to deliver tailored, scalable, and secure digital products that drive success for our clients, ensuring reliability and innovation at every step.',
      icon: 'fa fa-bullseye',
    },
    {
      id: 4,
      title: 'Our Values',
      description: 'Innovation, reliability, customer focus, and a commitment to excellence.',
      icon: 'fa fa-handshake-o',
    },
  ];

  return (
    <section className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="holderCircle">
              <div className="round"></div>
              <div className="dotCircle">
                {aboutContent.map((content, index) => (
                  <span
                    className={`itemDot ${index === 0 ? "active" : ""} itemDot${index + 1}`}
                    data-tab={index + 1}
                    key={index}
                  >
                    <i className={content.icon}></i>
                    <span className="forActive"></span>
                  </span>
                ))}
              </div>
              <div className="contentCircle">
                {aboutContent.map((content, index) => (
                  <div
                    className={`CirItem ${index === 0 ? "active" : ""} CirItem${index + 1}`}
                    key={index}
                  >
                    <h2>{content.title}</h2>
                    <p>{content.description}</p>
                    <i className={content.icon}></i>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 p-5 mt-5">
            <div
              className="about-col ms-5 fade-out"
              ref={(el) => (aboutRef.current[0] = el)}
            >
              <i className="fa fa-lightbulb-o"></i>
              <h2>Building the Future with Innovation</h2>
              <p>
                At our core, we are driven by innovation, creativity, and a relentless pursuit of excellence.
                <br />We collaborate with businesses to turn ideas into reality, leveraging modern technology to solve complex challenges.
                <br />Our solutions are designed with scalability, security, and efficiency in mind, ensuring long-term success for our clients.
                <br />With a customer-centric approach, we strive to bridge the gap between technology and business goals, delivering digital experiences that stand the test of time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;