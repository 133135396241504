import React, { useState, useEffect } from 'react';
import '../style/testimonials.css';

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonials = [
    {
      quote:
        "CRUDOps helped us build a smooth and efficient communication platform. Their expertise made a big difference in our project!" ,
      name: "Dileep C A ",
      designation: "Director-Ringme",
      src:
        "/testimonials/Dileep.jpg"
    },
    // {
    //   quote:
    //   "Our website turned out amazing! CRUDOps delivered a clean, user-friendly design that perfectly showcases our products." ,
    //   name: " Jacob Mathew",
    //   designation: "PicFrames.co.uk Team",
    //   src:
    //     "/testimonials/.jpg"
    // },
    {
      quote:
      "Managing restaurant billing has never been this simple! CRUDOps built an intuitive and reliable solution for us.", 
      name: "  Basil Sunny",
      designation: "Davids Lad -Founder",
      src:
        "/testimonials/Basil.jpg"
    },
    {
      quote:
      "We at Aharya are delighted with the beautiful website Crudops created, perfectly reflecting our passion for Indian classical dance and arts education.", 
      name: "  Praveen Kaimadathil",
      designation: "Founder-Aharya",
      src:
        "/testimonials/Praveen.jpg"
    },
    // {
    //   quote:
    //   "Our website beautifully represents our work, thanks to CRUDOps. A fantastic job by their team!", 
    //   name: "Amal Das",
    //   designation: "KAD Home Interiors Team",
    //   src:
    //     "/testimonials/.jpg"
    // },
  ];

  const handleNext = () => {
    setActiveIndex((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setActiveIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonial-section">
      <div className='container'>
        <div className='row'>
          <div className='hidden-xs hidden-sm col-md-4 col-lg-4 testimonial-title-dv'>
            <h2 className='testimonial-title'><span>Happy</span><br/><span>Clients</span></h2>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8'>
            <div className="testimonial-grid">
              <div className="image-container">
                {testimonials.map((testimonial, index) => (
                  <img
                    key={index}
                    src={testimonial.src}
                    alt={testimonial.name}
                    className="testimonial-image"
                    style={{
                      opacity: index === activeIndex ? 1 : 0,
                      transform: `scale(${index === activeIndex ? 1 : 0})`,
                      transition: "all 0.6s ease-in-out"
                    }}
                  />
                ))}
              </div>
              <div className="testimonial-content">
                <div>
                  <h3 className="name">{testimonials[activeIndex].name}</h3>
                  <p className="designation">{testimonials[activeIndex].designation}</p>
                  <p className="quote">{testimonials[activeIndex].quote}</p>
                </div>
                <div className="arrow-buttons">
                  <button className="arrow-button prev-button" onClick={handlePrev}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                    </svg>
                  </button>
                  <button className="arrow-button next-button" onClick={handleNext}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Testimonials;