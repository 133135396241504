import React from "react";
import Slider from "react-slick";
import '../style/client.css';

const Clients = () => {
  const logos = [
    '/clients/sijori.jpg',
    '/clients/tharakacas.jpg',
    '/clients/Ringme.webp',
    '/clients/davidslad.jpg',
    '/clients/picframes.png',
    '/clients/tharakacas.jpg',
    '/clients/Ringme.webp',
    '/clients/davidslad.jpg',
    '/clients/picframes.png',
  ];

  const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <section className="client-section">
      <div className="container text-center">
      <h2>Our Partners</h2>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="slide">
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
    </section>
  );
};

export default Clients;
