import React from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import '../style/career.css';

const Career = () => {
  const navigate = useNavigate(); // Hook for navigation

  const handleLearnMoreClick = () => {
    navigate('/careers-details'); // Navigate to a new page (you can change the path)
  };

  return (
    <section className="career-section">
      <div className="career-container">
        {/* Right Card: Image/Video with Tagline (Moved to Left) */}
        <div className="career-right-card">
          <div className="career-image-container">
            <video
              autoPlay
              loop
              src="https://videos.pexels.com/video-files/3249678/3249678-uhd_2560_1440_25fps.mp4" // Replace with your image or video
              alt="Career Opportunity"
              className="career-image"
            />
            {/* <p className="career-tagline">to reimagine what's possible</p> */}
          </div>
        </div>

        {/* Left Card: Career Opportunity (Moved to Right) */}
        <div className="career-left-card" onClick={handleLearnMoreClick}>
          <h2 className="career-title">Grow with Us</h2>
          <p className="career-description">
          At CRUDOps, we’re looking for talented individuals to build great technology solutions. If you're ready to learn, collaborate, and make an impact, explore our open positions today!
          </p>
          <button className="learn-more-btn">Learn More</button>
        </div>
      </div>
    </section>
  );
};

export default Career;